
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionError',
  setup() {


    return {
      
    };
  },
});
