// @/services/plaidService.ts
import { GenericAPIResponse } from '@/types';

// Create link tokens
export const createLinkTokenAuth = async (): Promise<string | null> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  try {

    const response = await fetch(`${process.env.VUE_APP_API_URL}/plaid/link-auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to update profile');
    }

    const data = await response.json();

    return data.linkToken;

  } catch (error) {
    console.error('Error creating link token:', error);
    return null;
  }

}

export const createLinkTokenId = async (): Promise<string | null> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  try {

    const response = await fetch(`${process.env.VUE_APP_API_URL}/plaid/link-id`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to update profile');
    }

    const data = await response.json();

    return data.linkToken;

  } catch (error) {
    console.error('Error creating link token:', error);
    return null;
  }

}


// Update 
export const connectBank = async (linkToken: string): Promise<GenericAPIResponse | null> => {

  console.log("connecting bank");

  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  try {

    const response = await fetch(`${process.env.VUE_APP_API_URL}/plaid/connect-bank`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ linkToken })
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to connect bank');
    }

    const data = await response.json();

    console.log(data)

    return data;

  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};
