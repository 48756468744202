

import {defineComponent, ref, shallowRef} from 'vue';

import EnterEmailView from '@/views/Unauthenticated/Signup/Flow/EnterEmailView.vue';
import SelectRoleView from '@/views/Unauthenticated/Signup/Flow/SelectRoleView.vue';
import CreateAccountView from '@/views/Unauthenticated/Signup/Flow/CreateAccountView.vue';
import VerifyView from '@/views/Unauthenticated/Signup/Flow/VerifyView.vue';
import ChoosePassword from '@/views/Unauthenticated/Signup/Flow/ChoosePasswordView.vue';

export default defineComponent( {
  components: {
    EnterEmailView,
    SelectRoleView,
    CreateAccountView,
    VerifyView, 
    ChoosePassword
  },

  setup(){

    const steps = [EnterEmailView, CreateAccountView, VerifyView, ChoosePassword];
    const currentStep = ref(0);

    const activeComponent = shallowRef(steps[currentStep.value]);

    const next = () => {
      if (currentStep.value < steps.length - 1) {
        currentStep.value++;
        activeComponent.value = steps[currentStep.value];
      }
    }

    return {
      activeComponent,
      next
    }
  }
  
})

