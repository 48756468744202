// @/services/usrService.ts

import { NewUser } from '../types';

export async function signupUser(newUser: NewUser & { password: string }) {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/usr/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUser),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Server responded with ${response.status}: ${errorData.message}`);
    }

    return await response.json();
  } catch (error) {
    console.error('An error occurred while signing up', error);
    throw error;
  }
}

// Forgot Password

export type ForgotPasswordRequest = {
  clientIP?: string;
  email: string;
  phone?: string;
};

export async function forgotPassword(requestData: ForgotPasswordRequest) {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/usr/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Server responded with ${response.status}: ${errorData.message}`);
    }

    return await response.json();
  } catch (error) {
    console.error('An error occurred while resetting the password', error);
    throw error;
  }
}
