
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SenderSupport',
  data() {
    return {
      whatsappLogo: require('@/assets/images/whatsapp_logo.svg')
    }
  }
})
