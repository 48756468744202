import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4edcc7fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  if: "",
  production: "",
  class: "row d-flex justify-content-between"
}
const _hoisted_2 = { class: "text-muted extra-small" }
const _hoisted_3 = { class: "text-muted extra-small" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted extra-small"
}
const _hoisted_5 = {
  key: 1,
  class: "text-muted extra-small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$setup.production)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("small", _hoisted_2, "IP: " + _toDisplayString($setup.ip), 1),
        _createElementVNode("small", _hoisted_3, "IP Location: " + _toDisplayString($setup.ipLocation), 1),
        ($setup.gps)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, "GPS: " + _toDisplayString($setup.gps), 1))
          : _createCommentVNode("", true),
        ($setup.gpsLocation)
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, "GPS Location: " + _toDisplayString($setup.gpsLocation), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}