import { VerificationRequestResult, VerificationCheckResult } from '@/types';

export const authenticatedSendCode = async (): Promise<VerificationRequestResult> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  const response = await fetch(`${process.env.VUE_APP_API_URL}/verify/authenticated-send-code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  if (!response.ok) {
    throw new Error('Failed to send verification code');
  }

  return await response.json();
};

export const authenticatedVerifyCode = async (code: string): Promise<VerificationCheckResult> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  const response = await fetch(`${process.env.VUE_APP_API_URL}/verify/authenticated-verify-code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({ code })
  });

  if (!response.ok) {
    throw new Error('Failed to verify code');
  }

  return await response.json();
};

export const unauthenticatedSendCode = async (phone: string): Promise<VerificationRequestResult> => {
  const response = await fetch(`${process.env.VUE_APP_API_URL}/verify/unauthenticated-send-code`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ phone })
  });

  if (!response.ok) {
    throw new Error('Failed to send verification code');
  }

  return await response.json();
};

export const unauthenticatedVerifyCode = async (phone: string, code: string): Promise<VerificationCheckResult> => {
  const response = await fetch(`${process.env.VUE_APP_API_URL}/verify/unauthenticated-verify-code`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ phone, code })
  });

  if (!response.ok) {
    throw new Error('Failed to verify code');
  }

  return await response.json();
};