
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import LoaderAnimation from "@/components/LoaderAnimation.vue";
import { addRecipient } from "@/services/senderService";

import {
  capitalize,
  validateMiddleInitial,
  containsOnlyLetters,
} from "@/helpers/formatting";

export default defineComponent({
  name: "AddRecipient",
  components: {
    LoaderAnimation,
  },
  setup() {
    const router = useRouter();

    const isLoading = ref(false);
    const firstName = ref("");
    const lastName = ref("");
    const middleInitial = ref("");
    const bankAccount = ref("");
    const ifsc = ref("");
    const upi = ref("");
    const firstNameValid = ref(true);
    const lastNameValid = ref(true);
    const middleInitialValid = ref(true);
    const phone = ref("");
    const phoneValid = ref(true);
    const bankAccountValid = ref(true);
    const ifscValid = ref(true);
    const upiValid = ref(true);
    const noUpiAvailable = ref(true); // Switch to false when turning on UPI
    const errorMessage = ref("");

    const addPlus = () => {
      if (phone.value.trim() === "") {
        phone.value = "+91 ";
      }
    };

    const formatPhoneNumber = () => {
      let digits = phone.value.replace(/\D/g, "");
      if (digits.length > 12) {
        digits = digits.slice(0, 12); // Limit to 12 digits
      }

      const countryCode = "+" + digits.slice(0, 2);
      const remainingDigits = digits.slice(2);

      if (remainingDigits.length === 10 && countryCode === "+91") {
        phone.value =
          countryCode + " " + remainingDigits.replace(/(\d{5})(\d+)/, "$1 $2");
      } else {
        phone.value = countryCode + remainingDigits;
      }
    };

    const formCapitalize = () => {
      firstName.value = capitalize(firstName.value);
      lastName.value = capitalize(lastName.value);
    };

    const capitalizeIFSC = () => {
      ifsc.value = ifsc.value.toUpperCase();
    };

    const formValidateMiddleInitial = () => {
      middleInitial.value = validateMiddleInitial(
        middleInitial.value.charAt(0)
      );
    };

    const validateBankAccount = () => {
      // Add your bank account validation logic here
      // For example, checking if it's a number and has the correct length
      bankAccountValid.value = /^\d{9,18}$/.test(bankAccount.value);
    };

    const validateIFSC = () => {
      // Add your IFSC validation logic here
      // For example, checking if it follows the correct format
      ifscValid.value = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/.test(ifsc.value);
    };

    const validateUPI = () => {
      // UPI ID is optional, but if provided, it should follow the pattern 'text@provider'
      upiValid.value =
        noUpiAvailable.value ||
        (upi.value.trim() !== "" &&
          /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+$/i.test(upi.value));
    };

    const addNewRecipient = async () => {
      isLoading.value = true;
      firstNameValid.value =
        firstName.value.trim() !== "" && containsOnlyLetters(firstName.value);
      lastNameValid.value =
        lastName.value.trim() !== "" && containsOnlyLetters(lastName.value);
      middleInitialValid.value =
        middleInitial.value.trim() === "" ||
        containsOnlyLetters(middleInitial.value);

      // Check if the phone number is exactly 12 digits (including country code)
      if (
        phone.value.replace(/\D/g, "").length == 12 &&
        phone.value.startsWith("+91")
      ) {
        phoneValid.value = true;
      } else {
        phoneValid.value = false;
      }

      validateBankAccount();
      validateUPI();

      if (noUpiAvailable.value) {
        validateBankAccount();
        validateIFSC();
      }

      // If name is not empty, email and phone number are valid, proceed
      if (
        firstNameValid.value &&
        lastNameValid.value &&
        middleInitialValid.value &&
        phoneValid.value &&
        (noUpiAvailable.value
          ? bankAccountValid.value && ifscValid.value
          : true) &&
        (!noUpiAvailable.value || upiValid.value)
      ) {
        try {
          // Add the recipient
          const newRecipientId = await addRecipient(
            firstName.value,
            lastName.value,
            middleInitial.value,
            phone.value,
            bankAccount.value,
            ifsc.value,
            upi.value
          );

          if (newRecipientId) {
            router.push({
              name: "addConfirmation",
              params: { id: newRecipientId },
            });
          }
        } catch (error) {
          errorMessage.value = "Error adding new recipient.";
        }
      }
      isLoading.value = false;
    };

    return {
      firstName,
      lastName,
      middleInitial,
      firstNameValid,
      lastNameValid,
      middleInitialValid,
      phone,
      phoneValid,
      bankAccount,
      ifsc,
      capitalizeIFSC,
      bankAccountValid,
      ifscValid,
      upiValid, // Make sure this is returned if it's not already
      upi,
      isLoading,
      addPlus,
      formatPhoneNumber,
      formCapitalize,
      formValidateMiddleInitial,
      addNewRecipient,
      validateBankAccount,
      validateIFSC,
      validateUPI,
      noUpiAvailable,
      errorMessage,
    };
  },
});
