// @/services/statusService.ts

type ServiceStatus = 'Service is up and running!' | 'Service unavailable.' | 'Unable to connect.';

export const checkStatus = async (): Promise<ServiceStatus> => {

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/status`);

    if (response.ok) {  // Check if HTTP status code is in the range 200-299
      const data = await response.json();
      if (data.status === 'OK') {
        return 'Service is up and running!';
      } else {
        return 'Service unavailable.';
      }
    } else {
      return 'Service unavailable.';
    }
  } catch (error) {
    if (error instanceof TypeError && error.message.startsWith('Failed to fetch')) {
      return 'Unable to connect.'
    } else {
      return 'Service unavailable.';
    }
  }
};
