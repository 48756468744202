
import { defineComponent, ref, watch } from 'vue';
import { store } from '@/store';
import { NewUser } from '@/types'; 
import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';
import InvitePrompt from '@/components/InvitePrompt.vue'; 
import validator from 'validator';
import { verifyCollegeEmail } from '@/services/authService';

export default defineComponent({
	name: 'signupEmail',
	emits: ['next'],
	components: {
		LoginSignupWrapper,
		InvitePrompt
	},
	setup(_, { emit }){
		const email = ref('');
		const emailValid = ref(true);
		const showInvitePrompt = ref(false); 
		const inviteVerified = ref(false);

		const submitEmail = async () => {
			if (!validator.isEmail(email.value)) {
				emailValid.value = false;
			} else {
				try {
					const { valid } = await verifyCollegeEmail(email.value);
					if (!valid) {
						showInvitePrompt.value = true; // Show passphrase prompt
						watch(inviteVerified, (newValue) => {
							if (newValue) {
								emailValid.value = true;
								const newUser: NewUser = {
									email: email.value,
									sender: false, // Default value, adjust as necessary
									firstName: '', // Default or placeholder value
									lastName: '', // Default or placeholder value
									middleInitial: '', // Default or placeholder value
									dob: '', // Default or placeholder value
									phone: '', // Default or placeholder value
									country: 'Canada' // As specified
								};

								store.setNewUser(newUser); // Save the email to the store
								emit('next'); // Notify the parent component to go to the next step
							}
						});
					} else {
						emailValid.value = true;
						const newUser: NewUser = { email: email.value, country: 'Canada' } as NewUser; // Create a new user object
						store.setNewUser(newUser); // Save the email to the store
						emit('next');
					}
				} catch (error) {
					console.error("Failed to verify email:", error);
				}
			}
		}

		const resetSignup = () => {
			email.value = '';
			emailValid.value = true;
			showInvitePrompt.value = false;
		}

		return {
			email,
			submitEmail,
			emailValid,
			showInvitePrompt, // Return showPassphrasePrompt
			inviteVerified,
			resetSignup
		}
  }
})
