
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getRecipients, deleteRecipient } from '@/services/senderService';
import { Recipient } from '@/types';
import { store } from '@/store';

export default defineComponent({
  name: 'SenderRecipients',
  setup() {
    const recipients = ref<Recipient[]>([]);
		const selectedRecipient = ref<Recipient | null>(null);
    const searchQuery = ref<string>('');
    const dropdownVisible = ref<boolean>(false);
    const router = useRouter();
		const route = useRoute();
  
		const isDeleteMode = ref<boolean>(false);

    onMounted(async () => {
      recipients.value = await getRecipients();
    });

		watch(() => route.query, () => {
      // React to query change
			selectedRecipient.value = null;
      // Perform actions based on the new query parameters
    }, { deep: true });

    const filteredRecipients = computed(() => {
      const lowerCaseQuery = searchQuery.value.toLowerCase();
      return recipients.value.filter((recipient) =>
      recipient.firstName.toLowerCase().includes(lowerCaseQuery) ||
      recipient.lastName.toLowerCase().includes(lowerCaseQuery) 
      );
    });

		const showDetails = (recipient: Recipient) => {
      selectedRecipient.value = recipient;
    };

		const clearSelectedRecipient = () => {
      selectedRecipient.value = null;
    };

    const goToAddRecipient = () => {
      router.push({ name: 'addRecipient' });
    };

		const deleteRecipientHandler = async (recipient: Recipient) => {
			await deleteRecipient(recipient.id); // Assuming deleteRecipient is an async function
			recipients.value = recipients.value.filter(r => r.id !== recipient.id);
			isDeleteMode.value = false; // Reset delete mode
			selectedRecipient.value = null; // Reset selected recipient
		};

    const redirectToTransfer = (recipient: Recipient) => {
      store.setSelectedRecipient(recipient);
      router.push({ name: 'sendDetails' });
    };

		const getCustomValue = (name: string): string => {
      if (!selectedRecipient.value || !selectedRecipient.value.custom) {
        return 'Not Available';
      }
      const item = selectedRecipient.value.custom.find((c) => c.Name.toLowerCase() === name.toLowerCase());
      return item && item.Value !== '' ? item.Value : 'Not Available';
    };

    return {
      recipients: filteredRecipients,
      searchQuery,
      dropdownVisible,
      redirectToTransfer,
      goToAddRecipient,
      filteredRecipients,
			selectedRecipient,
      showDetails,
      clearSelectedRecipient,
			isDeleteMode,
			deleteRecipientHandler,
			getCustomValue
    };
  }
})
