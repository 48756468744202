// @/services/txService.ts

import { FeeAndRateData } from '@/types'

export async function getFeeAndRate(): Promise<FeeAndRateData> {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  const response = await fetch(`${process.env.VUE_APP_API_URL}/tx/rates`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch fee and rate');
  }

  return await response.json();
}

export async function sendMoney(
	recipientId: string,
	totalAmount: string,
)  {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }
  const sendMoneyRequest = {
    recipientId,
    totalAmount
  
  };
  const response = await fetch(`${process.env.VUE_APP_API_URL}/tx/sendMoney`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(sendMoneyRequest)
  });

  const data = await response.json(); 

  if (!response.ok) {
    throw new Error(data.message || 'Failed to initiate transfer');
  }

  return data;

}