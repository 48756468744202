
import { defineComponent, ref, Ref, onMounted } from 'vue';
import { createLinkTokenAuth, connectBank } from '@/services/plaidService';
import { useRouter } from 'vue-router';
import { GenericAPIResponse, PlaidObject, PlaidLinkHandler } from '@/types';

declare global {
  interface Window {
    Plaid: PlaidObject;
  }
}

export default defineComponent({
  name: 'PlaidLink',
  props: {
    onLoad: Function,
    onSuccess: Function,
    onExit: Function,
    onEvent: Function,
    source: {
      type: String,
      required: false, // or true, if it's always required
    }
  },
  setup(props) {

    const router = useRouter(); // Import router

    const plaid: Ref<PlaidObject | null> = ref(null);
    const linkHandler: Ref<PlaidLinkHandler | null> = ref(null);
    const linkToken = ref<string | null>(null);

    const fetchLinkToken = async () => {
      try {
        linkToken.value = await createLinkTokenAuth();
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    };

    const link_open = async (e: Event) => {
      e.preventDefault();
      await fetchLinkToken();
      if (!linkToken.value) {
        console.error("Link token is missing.");
        // Optionally, you could show a user-friendly error message here.
        return;
      }
      if (plaid.value != null) {
        if (linkHandler.value != null) {
          linkHandler.value.destroy();
          linkHandler.value = null;
        }
        linkHandler.value = plaid.value.create({
          token: linkToken.value,
          onLoad: () => {
            if (props.onLoad) props.onLoad();
          },
          onSuccess: async (public_token: string, metadata: Record<string, unknown>) => {

            try {
              const connectBankResult = await connectBank(public_token) as GenericAPIResponse;
              if (connectBankResult && !connectBankResult.error) {
                router.push({ name: 'connectConfirmation', params: { source: props.source} });
              }
            } catch (error) {
              console.error('Error connecting bank:', error);
							router.push({ name: 'connectError' });
            }

            if (props.onSuccess) props.onSuccess(public_token, metadata);
          },
          onExit: (err: Error, metadata: Record<string, unknown>) => {
            if (props.onExit) props.onExit(err, metadata);
						if (err) {
							router.push({ name: 'connectError' });
						}
          },
          onEvent: (eventName: string, metadata: Record<string, unknown>) => {
            if (props.onEvent) props.onEvent(eventName, metadata);						
          }
        });
        linkHandler.value.open();
      }
    };

    onMounted(() => {
      if (window.Plaid) {
        plaid.value = window.Plaid;
        return;
      }

      const linkScript = document.createElement('script');
      linkScript.async = true;
      linkScript.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js');
      document.head.appendChild(linkScript);

      linkScript.onload = () => {
        plaid.value = window.Plaid;
      };
    });

    return {
      link_open,
    };
  },
});
