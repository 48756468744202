
import { defineComponent, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';
import { checkAccount } from '@/services/accountService';

export default defineComponent({
  name: 'CompleteConfirmation',
  setup() {
  const router = useRouter();

  const newRecipient = computed(() => store.selectedRecipient);

	const fetchAccountData = async () => {
		try {
			const data = await checkAccount(); // Directly call the checkAccount service
			store.setAccountCheckData(data); 
		} catch (error) {
			console.error("Failed to fetch account check data:", error);
			// Handle the error as needed
		}
  };

	onMounted(() => {
		fetchAccountData();
	});

  const redirectToTransfer = () => {

    router.push({ name: 'selectRecipient' }); // Assuming "senderHome" is the name of the route.
  };

  return {
    newRecipient,
    redirectToTransfer
  };
  }
});
