<!-- @/components/LoaderAnimation.vue --> 

<template>

	<div class="loadingio-spinner-blocks-4pbytlmifob">
		<div class="ldio-mcuc57kb0l">
			<div style='left:50px;top:50px;animation-delay:0s'></div>
			<div style='left:84px;top:50px;animation-delay:0.16891891891891891s'></div>
			<div style='left:118px;top:50px;animation-delay:0.33783783783783783s'></div>
			<div style='left:50px;top:84px;animation-delay:1.1824324324324325s'></div>
			<div style='left:118px;top:84px;animation-delay:0.5067567567567568s'></div>
			<div style='left:50px;top:118px;animation-delay:1.0135135135135136s'></div>
			<div style='left:84px;top:118px;animation-delay:0.8445945945945946s'></div>
			<div style='left:118px;top:118px;animation-delay:0.6756756756756757s'></div>
		</div>
	</div>

</template>

<style scoped>

@keyframes ldio-mcuc57kb0l {
  0% { background: #00adee }
  12.5% { background: #00adee }
  12.625% { background: #313e5e }
  100% { background: #313e5e }
}
.ldio-mcuc57kb0l div {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #313e5e;
  animation: ldio-mcuc57kb0l 1.3513513513513513s linear infinite;
}
.loadingio-spinner-blocks-4pbytlmifob {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-mcuc57kb0l {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-mcuc57kb0l div { box-sizing: content-box; }

</style>
