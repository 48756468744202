// @/helpers/navItems.ts

export const senderNavItems = [
  { href: 'selectRecipient', icon: 'send', name: 'Send money' },
  { href: 'senderRecipients', icon: 'people', name: 'Recipients' },
  { href: 'senderTransactions', icon: 'receipt', name: 'Transactions' },
  { href: 'senderRewards', icon: 'gem', name: 'Rewards' },
  { href: 'senderSupport', icon: 'chat-left-text-fill', name: 'Support' },
  { href: 'senderAccount', icon: 'person-square', name: 'Account' }
];

export const recipientNavItems = [
  { href: '#', icon: 'send', name: 'Request money' },
  { href: '#', icon: 'people', name: 'Contacts' },
  { href: '#', icon: 'receipt', name: 'Transactions' },
  { href: '#', icon: 'gem', name: 'Rewards' },
  { href: '#', icon: 'chat-left-text-fill', name: 'Support' },
  { href: '#', icon: 'person-square', name: 'Account' }
];
