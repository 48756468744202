
	import { defineComponent } from 'vue'
	import VueQrcode from 'vue-qrcode'
	import { getPromo } from '@/services/accountService';
	import { getPromoResponse } from '@/types'

	export default defineComponent({
		name: 'SenderRewards',
		data() {
			return {
				promoData: null as getPromoResponse | null,
			};
		},
		components: {
			VueQrcode,
		},
		async mounted() {
			try {
				const promoResponse = await getPromo();
				this.promoData = promoResponse;
			} catch (error) {
				console.error('Failed to fetch promo data:', error);
			}
		}
	})
