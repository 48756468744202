
import { defineComponent } from 'vue';

import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';

export default defineComponent({
  name: 'serviceUnavailable',
  components: {
    LoginSignupWrapper,
  }
})
