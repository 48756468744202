// src/router/index.ts

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { verifyToken, logout } from '@/services/authService';

import RootView from '@/views/RootView.vue';

import ForgotPassword from '@/views/Unauthenticated/Login/ForgotPasswordView.vue';

import SignUpView from '@/views/Unauthenticated/Signup/SignUpView.vue';
import SignUpSuccess from '@/views/Unauthenticated/Signup/SignUpSuccessView.vue';

// Base layouts for the roles
import SenderLayout from '@/views/Authenticated/Sender/SenderLayout.vue';

import senderRoutes from './senderRoutes';

const routes: Array<RouteRecordRaw> = [

  // Root -> Login or Service Unavailabel 
  {
    path: '/',
    name: 'root',
    component: RootView
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  // Sign Up Flow
  {
    path: '/signup',
    name: 'signup',
    component: SignUpView
  },
  {
    path: '/signup-success',
    name: 'signupSuccess',
    component: SignUpSuccess
  },
  {
    path: '/sender',
    name: 'sender',
    component: SenderLayout,
    children: senderRoutes // Use the imported sender routes here
  },

  
]

const authRequiredRoutes = [
  'signupSuccess',
  ...senderRoutes.map(route => route.name),
];

// Exclude these routes from any token validation
const excludedRoutes = [
  'root',
  'forgotPassword',
  'signup'
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard to check for auth before entering each route
router.beforeEach(async (to, from, next) => {
  const token = sessionStorage.getItem('user-auth');
  const isAuthenticated = token !== null;

  // Bypass token validation for specific routes
  if (excludedRoutes.includes(to.name as string)) {
    next();
    return;
  }

  // If the route requires authentication and the user is not authenticated, redirect to root
  if (authRequiredRoutes.includes(to.name as string) && !isAuthenticated) {
    next({ name: 'root' });
    return;
  }

  // If the user is authenticated, verify the token
  if (isAuthenticated) {
    try {
      const isValid = await verifyToken(token, router);
      if (!isValid) {
        logout(router);
        next({ name: 'root' }); // Redirect to root or login page after logout
      } else {
        next(); // Token is valid, proceed to route
      }
    } catch (error) {
      console.error('Error during route navigation:', error);
      next(false); // In case of error, halt navigation
    }
  } else {
    next(); // No authentication required, proceed to route
  }
});

export default router
