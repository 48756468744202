

import { defineComponent } from 'vue';
import InstallPrompt from '@/components/PWAInstallPrompt.vue';
import GeoLocation from '@/components/GeoLocation.vue';

export default defineComponent({
  name: 'loginSignupWrapper',
  data() {
    return {
      logo: require('@/assets/images/logo_roundup.png'),
    }
  },
  components: {
    InstallPrompt,
    GeoLocation
  },
  props: {
    showInstallPrompt: {
      type: Boolean,
      default: false
    }
  }
})

