import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6174b1bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-card card" }
const _hoisted_2 = { class: "card-body my-3" }
const _hoisted_3 = {
  key: 0,
  class: "card-title",
  style: {"text-align":"center"}
}
const _hoisted_4 = {
  key: 1,
  class: "card-title",
  style: {"text-align":"center"}
}
const _hoisted_5 = {
  key: 2,
  class: "card-subtitle mt-5",
  style: {"text-align":"center"}
}
const _hoisted_6 = {
  key: 3,
  class: "transaction-id",
  style: {"text-align":"center"}
}
const _hoisted_7 = {
  key: 4,
  class: "transaction-id",
  style: {"text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.referenceNumber !== '0')
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, "Transaction sent."))
        : (_openBlock(), _createElementBlock("h1", _hoisted_4, "Possible problem with transfer.")),
      (_ctx.referenceNumber !== '0')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_5, "It's on its way!"))
        : _createCommentVNode("", true),
      (_ctx.referenceNumber !== '0')
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Reference Number: " + _toDisplayString(_ctx.routeReferenceNumber), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_7, "Please contact customer support to check the status of your transaction."))
    ])
  ]))
}