
import { defineComponent, ref } from 'vue';
import validator from 'validator';
import LoaderAnimation from '@/components/LoaderAnimation.vue';

import { forgotPassword } from '@/services/usrService';

import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';

export default defineComponent({
  name: 'forgotPassword',
  components: {
    LoginSignupWrapper,
    LoaderAnimation
  },
  setup() {
    const email = ref('');
    const emailValid = ref(true);
    const requestSubmitted = ref(false);
    const isLoading = ref(false);

    const submitRequest = async () => {      

      if (!validator.isEmail(email.value)) {
        emailValid.value = false;
        return;
      }

      isLoading.value = true;

      try {
        // Assuming the client IP is either fixed or you fetch it some other way
        const response = await forgotPassword({ email: email.value });

        if (response) {
          // Handle successful forgot password here
          requestSubmitted.value = true;
        }
      } catch (error) {
        console.error('An error occurred:', error);
        // Handle error case here, for example, you can set an error message state
      } finally {
        isLoading.value = false; // End loading
      }

    };

    return {
      email,
      emailValid,
      submitRequest,
      requestSubmitted,
      isLoading
    };
  },
})
