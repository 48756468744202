
import { defineComponent, toRef, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { store } from '@/store';
import { checkAccount } from '@/services/accountService';

export default defineComponent({
  name: 'TransactionSent',
	props: {
    referenceNumber: {
      type: [String, Number],
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const routeReferenceNumber = toRef(route.params, 'referenceNumber');

		const fetchAccountData = async () => {
		try {
			const data = await checkAccount(); // Directly call the checkAccount service
			store.setAccountCheckData(data); // Update store with the fetched data
		} catch (error) {
			console.error("Failed to fetch account check data:", error);
			// Handle the error as needed
		}
  };

	onMounted(() => {
		fetchAccountData();
	});

    return {
      routeReferenceNumber
    };
  },
});
