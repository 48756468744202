
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import LoaderAnimation from '@/components/LoaderAnimation.vue';

import { changePassword } from '@/services/accountService';
import { logout } from '@/services/authService';

export default defineComponent({
  name: 'ChangePassword',
  components: {
    LoaderAnimation
  },
  setup() {

    const oldPassword = ref('');
    const newPassword = ref('');
    const confirmNewPassword = ref('');

    const passwordsMatch = ref(true);
    const oldPasswordValid = ref(true);
    const newPasswordValidLength = ref(true);
    const newPasswordContainsLetter = ref(true);
    const newPasswordContainsNumber = ref(true);
    const newPasswordContainsUppercase = ref(true);
    const updateError = ref(false);

    const isLoading = ref(false); 

    const checkValidLength = (password: string) => password.length >= 9;
    const checkContainsLetter = (password: string) => /[a-zA-Z]/.test(password);
    const checkContainsNumber = (password: string) => /\d/.test(password);
    const checkContainsUppercase = (password: string) => /[A-Z]/.test(password);

    const router = useRouter();

    const updatePassword = async () => {
        // Assuming you have a method to verify the old password (implement this)
        oldPasswordValid.value = true; // replace with actual check

        console.log(oldPassword, newPassword, confirmNewPassword)

        newPasswordValidLength.value = checkValidLength(newPassword.value);
        newPasswordContainsLetter.value = checkContainsLetter(newPassword.value);
        newPasswordContainsNumber.value = checkContainsNumber(newPassword.value);
        newPasswordContainsUppercase.value = checkContainsUppercase(newPassword.value);
        passwordsMatch.value = newPassword.value === confirmNewPassword.value;

        if (oldPasswordValid.value && newPasswordValidLength.value && newPasswordContainsLetter.value && newPasswordContainsNumber.value && newPasswordContainsUppercase.value && passwordsMatch.value) {

            isLoading.value = true; 

            try {
                // Calling the API service to update the password
                await changePassword(oldPassword.value, newPassword.value);
                updateError.value = false;
                // Optionally redirect the user or display a success message
                logout(router);
            } catch (error) {
              isLoading.value = false;
                updateError.value = true;
                console.error('An error occurred:', error);
            }
        }
    
    };

    return {
        oldPassword,
        newPassword,
        confirmNewPassword,
        passwordsMatch,
        oldPasswordValid,
        newPasswordValidLength,
        newPasswordContainsLetter,
        newPasswordContainsNumber,
        newPasswordContainsUppercase,
        updateError,
        updatePassword,
        isLoading
    };
  }
})
