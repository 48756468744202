
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter from Vue Router


export default defineComponent({
  name: 'ConnectionError',
  setup() {
		const router = useRouter(); 
		// Define the connectBankAccount method to navigate to the connectBankAccount route
    const tryConnectingAgain = () => {
      router.push({ name: 'connectBankAccount' }).catch(err => {
        console.error(err);
      });
    };

    return {
      tryConnectingAgain
    };
  },
});
