
import { defineComponent } from 'vue';
import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';
import { store } from '@/store';
import { NewUser } from '@/types'; 

export default defineComponent({
  name: 'signupRole',
  emits: ['next'],
  components: {
    LoginSignupWrapper,
  },
  data() {
    return {
      senderIcon: require('@/assets/images/sender_icon.svg'),
      recipientIcon: require('@/assets/images/recipient_icon.svg')
    }
  },
  setup(props, { emit }) {

    const selectRole = (isSender: boolean) => {

			if (!store.newUser) {
				// Handle the case where newUser is null, e.g., initialize it or throw an error
				console.error("New user data is not available.");
				return;
			}

      const updatedUser: NewUser = {
				email: store.newUser.email || '', // Default value if undefined
				sender: isSender,
				firstName: store.newUser.firstName || '',
				lastName: store.newUser.lastName || '',
				middleInitial: store.newUser.middleInitial || '',
				dob: store.newUser.dob || '',
				phone: store.newUser.phone || '',
				country: store.newUser.country || 'Canada'
			};
      store.setNewUser(updatedUser);
      emit('next'); // Emit next event to move to the next step
    };

    return {
      selectRole
    }
  }
})
