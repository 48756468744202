

import { defineComponent, ref, computed, onMounted } from 'vue';
import { store } from '@/store';
import { useRouter } from 'vue-router';

import { sendMoney } from '@/services/txService';
import { getBankInfo } from '@/services/senderService'; 
import { BankInfo, Recipient } from '@/types'; 

import StepIndicator from '@/components/StepIndicator.vue';
import LoaderAnimation from '@/components/LoaderAnimation.vue';
import VerifyCode from '@/components/VerifyCode.vue';

export default defineComponent({
  name: 'ReviewTransfer',
  components: {
    StepIndicator,
    LoaderAnimation,
		VerifyCode
  },
  setup() {
    const router = useRouter();

		const accountCheckData = computed(() => store.accountCheckData);

    // Use a reactive variable to track checkbox state.
    const isCheckboxChecked = ref(false);
		const isVerificationRequired = ref(false);
    const isLoading = ref(false);
		const bankInfo = ref('');

		onMounted(async () => {
      if (accountCheckData.value && !accountCheckData.value.profileComplete) {
				router.push({ name: 'senderHome' });
			}
			bankInfo.value = await getBankInfoData();
    });

    const selectedRecipient = computed<Recipient>(() => {
      return store.selectedRecipient || { 
        firstName: '',
        lastName: ''
      } as Recipient;
    });
		const getBankInfoData = async() => {
			try {
				const bankInfoData: BankInfo = await getBankInfo();
    
				// Check if bankName is null (or undefined)
				if (bankInfoData.bankName) {
					// const firstTwoWords = bankInfoData.bankName.split(' ').slice(0, 2).join(' ');
					// return (firstTwoWords + ' ' + bankInfoData.accountNumberHint);
          return (bankInfoData.accountNumberHint);
				} else {
					return (''); // Set it as empty string if no bank info
				}
			
			} catch (error) {
				console.error('Error fetching bank info:', error);
				return ('');
    }
		}

    const transactionDetails = computed(() => {
			const details = store.transactionDetails || {
				sendAmount: 0,
				totalFees: 0,
				totalAmount: 0,
				recipientGets: 0,
				destinationCurrency: 'CAD',
				shouldArriveBy: '', // We'll calculate this next
			};

			// Get the current UTC time
			const now = new Date();
			// Add 5 minutes to the current UTC time
			now.setMinutes(now.getMinutes() + 5);

			// Format the date and time in a readable format
			const options: Intl.DateTimeFormatOptions = {
				// year: 'numeric', month: 'numeric', day: 'numeric',
				hour: 'numeric', minute: 'numeric',
				hour12: true,
				timeZone: 'Asia/Kolkata' // Specify the timezone as IST
			};
			const formatter = new Intl.DateTimeFormat('en-IN', options);
			details.shouldArriveBy = formatter.format(now);

			return details;
		});

    const formatCurrency = (amount: number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    };
  
		const confirmContinue = async () => {
      // Trigger SMS verification before sending money
      isVerificationRequired.value = true;
    };

    const handleVerificationSuccess = async () => {
      // Once the SMS code is verified, proceed with sending money
			isVerificationRequired.value = false;
      isLoading.value = true;
      try {
				const details = transactionDetails.value || {};
        const roundedAmount = Math.ceil(details.sendAmount * 100) / 100;
        const sendMoneyResult = await sendMoney(selectedRecipient.value.id, roundedAmount.toString());
        const referenceNumber = sendMoneyResult.referenceNumber || 0;

        router.push({ name: 'transactionSent', params: { referenceNumber: referenceNumber } });
      } catch (error) {
        console.error("Failed to send money:", error);
				router.push({ name: 'transactionError' });
        // Handle the error appropriately, e.g., show an error message to the user
      } finally {
        isLoading.value = false;
      }
    };

    return {
      formatCurrency,
      selectedRecipient,
      transactionDetails,
      isCheckboxChecked,
      confirmContinue,
			isVerificationRequired,
			handleVerificationSuccess,
      isLoading,
			bankInfo
    };
  },
})

