
import { defineComponent, ref, onMounted } from 'vue'; 
import { authenticatedSendCode, authenticatedVerifyCode } from '@/services/verifyService';

export default defineComponent({
  name: 'VerifyAndCreate',
  setup(_, { emit }){

    const enteredCode = ref<string>('');
    const correctCode = ref(true);
		const isResendDisabled = ref(false);

    const limitToDigits = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const sanitizedValue = input.value.replace(/[^\d]/g, '').slice(0, 4);
      enteredCode.value = sanitizedValue;
    };

		onMounted(async () => {
      try {
        await authenticatedSendCode();
      } catch (error) {
        console.error('Failed to send verification code:', error);
      }
    });

		const resendCode = async () => {
      isResendDisabled.value = true;
      try {
        await authenticatedSendCode();
      } catch (error) {
        console.error('Failed to resend verification code:', error);
      }
      // Optionally, re-enable the button after a certain time
      setTimeout(() => {
        isResendDisabled.value = false;
      }, 10000); // 3 seconds
    };
		

    const submitCode = async () => {
			try {
				const isValid = await authenticatedVerifyCode(enteredCode.value);
				if (isValid) {
					emit('next');
				} else {
					correctCode.value = false;
				}
			} catch (error) {
				if (error instanceof Error && error.message === 'Invalid verification code') {
					// Handle the specific case of an invalid verification code
					correctCode.value = false;
				} else {
					// Handle other types of errors, possibly a generic error message
					console.error('Verification error:', error);
					correctCode.value = false;
				}
			}
		};

		const resetError = () => {
      correctCode.value = true;
    };

    return {
      submitCode,
      enteredCode,
      correctCode,
      limitToDigits,
			resendCode,
			isResendDisabled,
			resetError
    }

  }
});
