
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';

import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';

export default defineComponent({
  name: 'signupSuccess',
  emits: ['next'],
  components: {
    LoginSignupWrapper,
  },
  setup() {
    const router = useRouter();
    const timeoutRef = ref<number | undefined>();

    const redirectToSenderHome = () => {
      if (timeoutRef.value !== undefined) {
        clearTimeout(timeoutRef.value);  // Clear the timeout
      }
      router.push({ name: 'senderHome' }); // Assuming "senderHome" is the name of the route.
    };

    onMounted(() => {
			store.flushAccountCheckData(); 
      timeoutRef.value = setTimeout(redirectToSenderHome, 5000) as unknown as number; // Redirect after 5 seconds.
    });

    return {
      redirectToSenderHome
    };
  }
});
