

import { defineComponent } from 'vue';
import PlaidId from '@/components/PlaidId.vue';
import { useRoute, RouteParams } from 'vue-router';

export default defineComponent({
  name: 'ConnectAccount',
  computed: {
    sourceParam(): string {
      const route = useRoute();
      const source: string | string[] | RouteParams = route.params.source;
      return Array.isArray(source) ? source.join(',') : typeof source === 'string' ? source : '';
    }
  },
  components: {
    PlaidId
  },
  data() {
    return {
      q_x_plaid: require('@/assets/images/q_x_plaid.png'),
      isButtonClicked: false
    }
  },
})
