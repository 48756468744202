
import { defineComponent } from 'vue';

interface CardInfo {
	firstName: string,
	lastName: string,
  last30dayVolume: string;
  rateRoundUp: string;
  rateOfficial: string;
}

export default defineComponent({
  props: {
    cardInfo: Object as () => CardInfo
  },
});
