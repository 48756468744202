
import { defineComponent, ref, onMounted } from 'vue';
import { store } from '@/store';
import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';
import { unauthenticatedSendCode, unauthenticatedVerifyCode } from '@/services/verifyService';

export default defineComponent({
  name: 'VerifyAndCreate',
  emits: ['next'],
  components: {
        LoginSignupWrapper,
    }, 
  setup(_, { emit }){

    const phoneNumber = ref(store.newUser?.phone); 
    const enteredCode = ref<string>('');
    const correctCode = ref(true);
		const isResendDisabled = ref(false);

    const limitToDigits = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const sanitizedValue = input.value.replace(/[^\d]/g, '').slice(0, 4);
      enteredCode.value = sanitizedValue;
    };

		onMounted(async () => {
      try {
        if (phoneNumber.value) {
					await unauthenticatedSendCode(phoneNumber.value);
				} else {
					console.error('Phone number is undefined');
				}
      } catch (error) {
        console.error('Failed to send verification code:', error);
      }
    });

		const resendCode = async () => {
      isResendDisabled.value = true;
      try {
        if (phoneNumber.value) {
					await unauthenticatedSendCode(phoneNumber.value);
				} else {
					console.error('Phone number is undefined');
				}
      } catch (error) {
        console.error('Failed to resend verification code:', error);
      }
      // Optionally, re-enable the button after a certain time
      setTimeout(() => {
        isResendDisabled.value = false;
      }, 10000); // 3 seconds
    };
		

    const submitCode = async () => {
			try {
				if (phoneNumber.value) {
					const isValid = await unauthenticatedVerifyCode(phoneNumber.value, enteredCode.value);
					if (isValid) {
						emit('next');
					} else {
						correctCode.value = false;
					}
				} else {
					console.error('Phone number is undefined');
				}
				
			} catch (error) {
				if (error instanceof Error && error.message === 'Invalid verification code') {
					// Handle the specific case of an invalid verification code
					correctCode.value = false;
				} else {
					// Handle other types of errors, possibly a generic error message
					console.error('Verification error:', error);
					correctCode.value = false;
				}
			}
		};

		const resetError = () => {
      correctCode.value = true;
    };

    return {
      submitCode,
      phoneNumber,
      enteredCode,
      correctCode,
      limitToDigits,
			resendCode,
			isResendDisabled,
			resetError
    }

  }
});
