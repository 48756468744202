
import { defineComponent, ref, getCurrentInstance, onMounted } from 'vue';
import { verifyPassphrase } from '@/services/authService';

export default defineComponent({
  name: 'PassphrasePrompt',
  setup() {
    const passphrase = ref('');
    const passphraseValid = ref(true);
		const emit = getCurrentInstance()?.emit;

		// leave on for dev		
		onMounted(() => {
			if (process.env.VUE_APP_PASS === 'FALSE') {
				emit?.('passphrase-verified', true);
			}
    });

    const checkPassphrase = async () => {
      passphraseValid.value = await verifyPassphrase(passphrase.value);
      if (passphraseValid.value) {
        emit?.('passphrase-verified', true);
      }
    };

    return {
      passphrase,
      passphraseValid,
      checkPassphrase
    };
  },
});
