
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getRecipients } from "@/services/senderService";
import { Recipient } from "@/types";
import { store } from "@/store";

export default defineComponent({
  name: "AddConfirmation",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const newRecipient = ref<Recipient | null>(null);
    const isLoading = ref(true);

    onMounted(async () => {
      const recipientId = route.params.id as string;
      if (recipientId) {
        try {
          const recipients = await getRecipients();
          newRecipient.value = recipients.find(recipient => recipient.id.toString() === recipientId) || null;
        } catch (error) {
          console.error("Error loading recipients:", error);
        } finally {
          isLoading.value = false;
        }
      } else {
        isLoading.value = false;
      }
    });

    const redirectToTransfer = () => {
      if (newRecipient.value) {
        store.setSelectedRecipient(newRecipient.value);
        router.push({ name: "sendDetails" });
      }
    };

    return {
      newRecipient,
      redirectToTransfer,
    };
  },
});
