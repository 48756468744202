
import { defineComponent, getCurrentInstance, ref, PropType } from 'vue';
import { formatPhoneNumber, toE164Format } from '@/helpers/formatting';
import { verifyPhoneHasBeenInvited } from '@/services/authService';

export default defineComponent({
  name: 'InvitePrompt',
	props: {
  resetSignup: {
    type: Function as PropType<(event: MouseEvent) => void>,
    required: true
  },
},
  setup() {
    
		const phone = ref('');
		const phoneValid = ref(true);
		const phoneInvited = ref(true);

		const emit = getCurrentInstance()?.emit;

		const formFormatPhoneNumber = () => {
      phone.value = formatPhoneNumber(phone.value, 'Canada');
    };

		const checkInvite = async () => {
    const phoneStandardized = toE164Format(phone.value, 'Canada');
			try {
					const phoneHasBeenInvited = await verifyPhoneHasBeenInvited(phoneStandardized);
					phoneInvited.value = phoneHasBeenInvited;
					if (phoneHasBeenInvited) {
							emit?.('invite-verified', true);
					} else {
							// Handle the case where the phone number has not been invited
							// This could involve setting an error message or updating the UI to reflect the status
					}
			} catch (error) {
					console.error("Error verifying phone invitation:", error);
					// Here, you can handle the error, for example, by setting an error message to be displayed to the user
					// errorMessage.value = "There was a problem verifying the invitation. Please try again later.";
					// Ensure you have defined `errorMessage` in your component's `setup` function if you plan to use it
			}
		};

    return {
			phone,
			phoneValid,
			phoneInvited,
			formFormatPhoneNumber,
			checkInvite,

    };
  },
});
