

import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';

import { Recipient } from '@/types';
import { getRecipients } from '@/services/senderService';

import StepIndicator from '@/components/StepIndicator.vue';

export default defineComponent({
  name: 'SelectRecipient',
  components: {
    StepIndicator
  },
  setup() {
    const router = useRouter();
    const searchQuery = ref<string>('');
    const selectedRecipient = ref<Recipient | null>(null);
    const dropdownVisible = ref<boolean>(false);
    const recipients = ref<Recipient[]>([]);

    const filteredRecipients = computed<Recipient[]>(() => {
      const lowerCaseQuery = searchQuery.value.toLowerCase();

      return recipients.value.filter((recipient: Recipient) => {
        return (recipient.firstName ? recipient.firstName.toLowerCase().includes(lowerCaseQuery) : false) ||
               (recipient.lastName ? recipient.lastName.toLowerCase().includes(lowerCaseQuery) : false);
      });
    });

    onMounted(async () => {
      recipients.value = await getRecipients();
    });

    watch(() => [searchQuery.value, filteredRecipients.value], ([newSearchQuery, newFilteredRecipients]) => {
      dropdownVisible.value = Boolean(newSearchQuery) && newFilteredRecipients.length > 0;
    });

    const selectRecipient = (recipient: Recipient) => {
      selectedRecipient.value = recipient;
      searchQuery.value = `${recipient.firstName} ${recipient.lastName}`;
      dropdownVisible.value = false;
    }

    const confirmSelectedRecipient = () => {
      if(selectedRecipient.value) {
        store.setSelectedRecipient(selectedRecipient.value);
        router.push({ name: 'sendDetails' });
      }
    }

    const addRecipient = () => {
      router.push({ name: 'addRecipient' }); // Assuming "senderHome" is the name of the route.
    };

    return {
      searchQuery,
      filteredRecipients,
      selectRecipient,
      selectedRecipient,
      dropdownVisible,
      confirmSelectedRecipient,
      addRecipient
    };
  },
  data() {
    return {
      recipientIcon: require('@/assets/images/recipient_icon.svg')
    }
  }
})
