
import { ref } from 'vue';

import LoginView from './Unauthenticated/Login/LoginView.vue';
import ServiceUnavailableView from './Unauthenticated/ServiceUnavailableView.vue';
import LoaderAnimation from '@/components/LoaderAnimation.vue';

import { checkStatus } from '@/services/statusService';

export default {
  components: {
    LoginView,
    ServiceUnavailableView,
    LoaderAnimation
  },
  setup() {
    const serviceAvailable = ref(false);
    const isLoading = ref(true);
    
    // Check the service status
    checkStatus().then(status => {

       if (status === 'Service is up and running!') {
        serviceAvailable.value = true;
      } else if (status === 'Service unavailable.') {
        serviceAvailable.value = false;
      } else if (status === 'Unable to connect.') {
        // We can make a new value to show a sepparte ConnectionError page... 
        serviceAvailable.value = false;
      }

      isLoading.value = false;
    }).catch(err => {
      console.error(err);
      isLoading.value = false;
    });

    return { 
      serviceAvailable,
      isLoading
    };
  }
};
