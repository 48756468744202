

import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { logout } from '@/services/authService';

import { senderNavItems, recipientNavItems } from '@/helpers/navItems';

export default defineComponent({
  name: 'sidebarNav',
  props: {
    userRole: {
      type: String,
      required: false,
      default: 'sender'
    }
  },
  setup(props) {

    const router = useRouter();
    const performLogout = () => logout(router);

    const displayedNavItems = computed(() => {
      return props.userRole === 'recipient' ? recipientNavItems : senderNavItems;
    });

    return {
      performLogout,
      displayedNavItems
    };
  },
  data() {
    return {
      logo: require('@/assets/images/logo.svg'),
    }
  },
});

