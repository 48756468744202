

import SidebarNav from '@/components/SidebarNav.vue';
import MobileNav from '@/components/MobileNav.vue';

export default {
  name: 'SenderLayout',
  components: {
    SidebarNav,
    MobileNav,
  },
}
