import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dbfbe28"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader-overlay"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderAnimation = _resolveComponent("LoaderAnimation")!
  const _component_LoginView = _resolveComponent("LoginView")!
  const _component_ServiceUnavailableView = _resolveComponent("ServiceUnavailableView")!

  return ($setup.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_LoaderAnimation)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        ($setup.serviceAvailable)
          ? (_openBlock(), _createBlock(_component_LoginView, { key: 0 }))
          : (_openBlock(), _createBlock(_component_ServiceUnavailableView, { key: 1 }))
      ]))
}