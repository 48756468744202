import { reactive } from 'vue';

import { NewUser, AuthenticatedUser, CheckAccountResult, Recipient, NewTransaction } from '@/types';

export const store = reactive({

  authenticatedUser: null as AuthenticatedUser | null,
  setAuthenticatedUser(user: AuthenticatedUser) {
    this.authenticatedUser = user;
  },

	accountCheckData: null as CheckAccountResult | null,
	setAccountCheckData(data: CheckAccountResult) {
		this.accountCheckData = data;
	},
	flushAccountCheckData() {
		this.accountCheckData = null;
	},

	newUser: null as NewUser | null,
	setNewUser(user: NewUser) {
		this.newUser = user;
	},
	flushNewUser() {
		this.newUser = null;
	},

	selectedRecipient: null as Recipient | null,
	setSelectedRecipient(recipient: Recipient) {
		this.selectedRecipient = recipient;
	},

	transactionDetails: null as NewTransaction | null,
	setTransactionDetails(transactionDetails: NewTransaction) { 
		this.transactionDetails = transactionDetails;
	},

	flush() {
		this.authenticatedUser = null;
		this.accountCheckData = null;
		this.newUser = null;
		this.selectedRecipient = null;
	}

})