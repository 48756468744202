
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StepIndicator',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    activeStep: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColClass(index: number, length: number) {
      if (index === 0 || index === length - 1) {
        return 'col-3';
      }
      return 'col step-indicator-line';
    }
  }
})
