
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';

export default defineComponent({
  name: 'CompleteConfirmation',
  setup() {
  const router = useRouter();

  const newRecipient = computed(() => store.selectedRecipient);

  const redirectToSupport = () => {

    router.push({ name: 'senderSupport' }); // Assuming "senderHome" is the name of the route.
  };

  return {
    newRecipient,
    redirectToSupport
  };
  }
});
