import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-card card mb-2" }
const _hoisted_2 = { class: "card-body my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AfterSignupCard = _resolveComponent("AfterSignupCard")!
  const _component_AfterProfileCompleteCard = _resolveComponent("AfterProfileCompleteCard")!
  const _component_AfterFirstTxCard = _resolveComponent("AfterFirstTxCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.accountCheckData && !_ctx.accountCheckData.profileComplete)
        ? (_openBlock(), _createBlock(_component_AfterSignupCard, {
            key: 0,
            cardInfo: _ctx.cardInfo
          }, null, 8, ["cardInfo"]))
        : (_ctx.accountCheckData && _ctx.accountCheckData.profileComplete && !_ctx.accountCheckData.hasSentMoney)
          ? (_openBlock(), _createBlock(_component_AfterProfileCompleteCard, {
              key: 1,
              cardInfo: _ctx.cardInfo
            }, null, 8, ["cardInfo"]))
          : (_openBlock(), _createBlock(_component_AfterFirstTxCard, {
              key: 2,
              cardInfo: _ctx.cardInfo
            }, null, 8, ["cardInfo"]))
    ])
  ]))
}