

import { defineComponent, ref, Ref, onMounted } from 'vue';
import { createLinkTokenId } from '@/services/plaidService';
import { PlaidObject, PlaidLinkHandler } from '@/types';


declare global {
  interface Window {
    Plaid: PlaidObject;
  }
}

export default defineComponent({
  name: 'PlaidId',
  props: {
    onLoad: Function,
    onSuccess: Function,
    onExit: Function,
    onEvent: Function,
    source: {
      type: String,
      required: false, // or true, if it's always required
    }
  },
  setup(props) {

    const plaid: Ref<PlaidObject | null> = ref(null);
    const verificationHandler: Ref<PlaidLinkHandler | null> = ref(null);
    const linkToken = ref<string | null>(null);

    const fetchLinkToken = async () => {
      try {
        linkToken.value = await createLinkTokenId();
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    };

    const link_open = async (e: Event) => {
      console.log('Button clicked');
      e.preventDefault();
      await fetchLinkToken();
      if (!linkToken.value) {
        console.error("Link token is missing.");
        // Optionally, you could show a user-friendly error message here.
        return;
      }
      console.log(linkToken.value)
      console.log(plaid.value)    
      if (plaid.value) {
        if (verificationHandler.value) {
          console.log('Destroying existing verification handler');
          verificationHandler.value.destroy();
          verificationHandler.value = null;
        }
        console.log('Creating new verification handler');
        verificationHandler.value = plaid.value.create({
          token: linkToken.value,
          onLoad: () => {
            if (props.onLoad) props.onLoad();
          },
          onSuccess: async (public_token: string, metadata: Record<string, unknown>) => {
            try{
              console.log('Success!')
            } catch (error) {
              console.error('Error during identity verification:', error);
            }
            if (props.onSuccess) props.onSuccess(public_token, metadata);
          },
          onExit: (err: Error, metadata: Record<string, unknown>) => {
            if (props.onExit) props.onExit(err, metadata);
          },
          onEvent: (eventName: string, metadata: Record<string, unknown>) => {
            if (props.onEvent) props.onEvent(eventName, metadata);
          }
        });
        console.log('Opening verification handler');
        verificationHandler.value.open();
      }
    };

    onMounted(() => {
      console.log('Component mounted');
      if (window.Plaid) {
        plaid.value = window.Plaid;
        return;
      }

      const linkScript = document.createElement('script');
      linkScript.async = true;
      linkScript.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js');
      document.head.appendChild(linkScript);

      linkScript.onload = () => {
        plaid.value = window.Plaid;
      };
    });

    return {
      link_open,
    };
  },
});
