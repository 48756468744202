
  import { ref, onMounted } from 'vue';

  export default {
    name: 'GeoLocation',
    setup() {
      const ip = ref('');
      const ipLocation = ref('');
      const gps = ref('');
      const gpsLocation = ref('');

			const production = process.env.VUE_APP_ENV != 'DEV';

      onMounted(async () => {
        // Fetch IP and location based on IP
        try {
          const ipResponse = await fetch('https://api.ipify.org?format=json');
          const ipData = await ipResponse.json();
          ip.value = ipData.ip;

          const geoResponse = await fetch(`${process.env.VUE_APP_API_URL}/location/ip?ip=${ip.value}`);

          const geoData = await geoResponse.json();

          ipLocation.value = geoData.location;
        } catch (error) {
          console.error(error);
        }

        // Get GPS-based location
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              gps.value = `${position.coords.latitude}, ${position.coords.longitude}`;
              // Convert GPS coordinates to location name
              try {

								const geoResponse = await fetch(`${process.env.VUE_APP_API_URL}/location/gps?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`);

                const geoData = await geoResponse.json();

                gpsLocation.value = geoData.location; // Get the formatted location name
              } catch (error) {
                console.error('Geocoding error:', error);
              }
            },
            (error) => {
              console.error('Geolocation error:', error);
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      });

      return { 
        ip,
        ipLocation,
        gps,
        gpsLocation,
				production
      };
    },
  };
