// src/router/senderRoutes.ts


import SenderHomeView from '@/views/Authenticated/Sender/Home/MainView.vue';
import SenderRecipientsView from '@/views/Authenticated/Sender/Recipients/MainView.vue';
import SenderTransactionsView from '@/views/Authenticated/Sender/Transactions/MainView.vue';
import SenderAccountView from '@/views/Authenticated/Sender/Account/MainView.vue';
import SenderRewardsView from '@/views/Authenticated/Sender/Rewards/MainView.vue';
import SenderSupportView from '@/views/Authenticated/Sender/Support/MainView.vue';

// Sender / Send Money Flow
import SelectRecipientView from '@/views/Authenticated/Sender/SendMoney/SelectRecipientView.vue';
import SendDetailsView from '@/views/Authenticated/Sender/SendMoney/SendDetailsView.vue';
import ReviewTransferView from '@/views/Authenticated/Sender/SendMoney/ReviewTransferView.vue';
import TransactionSentView from '@/views/Authenticated/Sender/SendMoney/TransactionSentView.vue';
import TransactionErrorView from '@/views/Authenticated/Sender/SendMoney/TransactionErrorView.vue';

// Sender / Invite New Recipient
import AddRecipientView from '@/views/Authenticated/Sender/Recipients/AddRecipientFlow/AddRecipientView.vue';
import AddConfirmationView from '@/views/Authenticated/Sender/Recipients/AddRecipientFlow/AddConfirmationView.vue';

// Sender / Add Bank
import ConnectBankAccountView from '@/views/Authenticated/Sender/Account/AddBankFlow/ConnectAccountView.vue';
import ConnectConfirmationView from '@/views/Authenticated/Sender/Account/AddBankFlow/ConnectConfirmationView.vue';
import ConnectErrorView from '@/views/Authenticated/Sender/Account/AddBankFlow/ConnectErrorView.vue';

// Sender / Profile Completion
import CompleteProfileView from '@/views/Authenticated/Sender/Account/ProfileCompletion/CompleteProfileView.vue';
import CompleteConfirmationView from '@/views/Authenticated/Sender/Account/ProfileCompletion/CompleteConfirmationView.vue';
import CompleteErrorView from '@/views/Authenticated/Sender/Account/ProfileCompletion/CompleteErrorView.vue';

// Sender / Verify Id
import VerifyIdView from '@/views/Authenticated/Sender/Account/VerifyIdFlow/VerifyIdView.vue';
import VerifyIdConfirmation from '@/views/Authenticated/Sender/Account/VerifyIdFlow/VerifyIdConfirmation.vue';

// Common
import ChangePasswordView from '@/views/Authenticated/Sender/Account/ChangePasswordView.vue';
import ChangeAddressView from '@/views/Authenticated/Sender/Account/ChangeAddressView.vue';
import ChangeEmailView from '@/views/Authenticated/Sender/Account/ChangeEmailView.vue';

const senderRoutes = [
  {
    path: 'home',
    name: 'senderHome',
    component: SenderHomeView
  },
  {
    path: 'recipients',
    name: 'senderRecipients',
    component: SenderRecipientsView
  },
  {
    path: 'transactions',
    name: 'senderTransactions',
    component: SenderTransactionsView
  },
  {
    path: 'rewards',
    name: 'senderRewards',
    component: SenderRewardsView
  },
  {
    path: 'support',
    name: 'senderSupport',
    component: SenderSupportView
  },
  {
    path: 'account',
    name: 'senderAccount',
    component: SenderAccountView
  },
  // Sender Views / Send Money Flow
  {
    path: 'new-transfer/recipient',
    name: 'selectRecipient',
    component: SelectRecipientView
  },
  {
    path: 'new-transfer/details',
    name: 'sendDetails',
    component: SendDetailsView
  },
  {
    path: 'new-transfer/review',
    name: 'reviewTransfer',
    component: ReviewTransferView
  },
  {
    path: 'new-transfer/confirmation/:referenceNumber',
    name: 'transactionSent',
    component: TransactionSentView,
    props: true 
  },
	{
    path: 'new-transfer/error',
    name: 'transactionError',
    component: TransactionErrorView
  },
  // Sender / Invite New Recipient
  {
    path: 'new-recipient/add',
    name: 'addRecipient',
    component: AddRecipientView
  },
  {
    path: 'new-recipient/add-confirmation/:id',
    name: 'addConfirmation',
    component: AddConfirmationView,
  },
  // Sender / Complete Profile
   {
    path: 'account/complete-profile',
    name: 'profileCompletion',
    component: CompleteProfileView,
  },
   {
    path: 'account/complete-profile-confirmation',
    name: 'profileCompletionConfirmation',
    component: CompleteConfirmationView,
  },
   {
    path: 'account/complete-profile-error',
    name: 'profileCompletionError',
    component: CompleteErrorView,
  },
  // Sender / Add Bank
  {
    path: 'add-bank/connect:source',
    name: 'connectBankAccount',
    component: ConnectBankAccountView,
  },
  {
    path: 'add-bank/confirmation:source',
    name: 'connectConfirmation',
    component: ConnectConfirmationView,
  },
	{
    path: 'add-bank/error',
    name: 'connectError',
    component: ConnectErrorView,
  },
  // Sender / Veirify Id
  {
    path: 'verify-id/connect:source',
    name: 'verifyId',
    component: VerifyIdView,
  },
  {
    path: 'verify-id/confirmation:source',
    name: 'verifyIdConfirmation',
    component: VerifyIdConfirmation,
  },
  // Common Views
  {
    path: '/account/change-password',
    name: 'senderChangePassword',
    component: ChangePasswordView
  },
  {
    path: '/account/change-address',
    name: 'senderChangeAddress',
    component: ChangeAddressView
  },
  {
    path: '/account/change-email',
    name: 'senderChangeEmail',
    component: ChangeEmailView
  }
];

export default senderRoutes;
