// @/services/authService.ts

import { Router } from 'vue-router';
import { AuthenticatedUser } from '@/types';
import { store } from '@/store';

export interface LoginResult {
  token: string;
  profile: {
    country: string;
  }
}

export const login = async (email: string, password: string): Promise<LoginResult> => {

  const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    throw new Error("Authentication failed");
  }

  const data = await response.json();

  if (data && data.token && data.profile) {

    const profile = data.profile
    const authenticatedUser: AuthenticatedUser = {
      country: profile.country,
      firstName: profile.firstName,
      lastName: profile.lastName
    };
    store.setAuthenticatedUser(authenticatedUser);
  } else {
    throw new Error("Authentication failed");
  }

  return data;
};

export const verifyToken = async (token: string, router: Router): Promise<boolean> => {

	if (!token) {
    // Redirect to the root page
    router.push({ name: 'root' });
    return false;
  }

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/verify-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    if (!response.ok) {
      throw new Error("Server responded with an error");
    }

    const data = await response.json();

    // Check if the token is expired
    if (data.expired) {
      return false;
    }

    return data.valid;
  } catch (error) {
    console.error("Failed to verify token: ", error);
    return false;
  }
};

export const logout = (router: Router)  => {

  // Remove the authentication token
  sessionStorage.removeItem('user-auth');
  
  // Reset the store
  store.flush();
  
  // Clear session data if any
  sessionStorage.clear();

  // Clear any cached data or other client-side stored information

  // Redirect to the login page
  router.push({ name: 'root' });
};

export const verifyPassphrase = async (passphrase: string): Promise<boolean> => {
	const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/verify-passphrase`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ passphrase })
  });
  
  const data = await response.json();
  return data.valid;
}

export const verifyCollegeEmail = async (email: string): Promise<{ valid: boolean; message: string }> => {
  const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/verify-email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  });

  const data = await response.json();
  return { valid: data.valid, message: data.message };
}

export const verifyPhoneHasBeenInvited = async (phone: string): Promise<boolean> => {
  const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/verify-invite`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ phone })
  });

  const data = await response.json();

	console.log(data);

  return data.valid;
}