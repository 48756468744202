
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { logout } from '@/services/authService';
import { Collapse } from 'bootstrap';

import { senderNavItems, recipientNavItems } from '@/helpers/navItems';

export default defineComponent({
  name: 'mobileNav',
  props: {
    userRole: {
      type: String,
      required: false,
      default: 'sender'
    }
  },
  setup(props) {
    const router = useRouter();
    const navbar = ref(null);

    const toggleNavbar = () => {
      if (navbar.value) {
        new Collapse(navbar.value, { toggle: true });
      }
    };

    const performLogout = () => logout(router);

    const displayedNavItems = computed(() => {
      return props.userRole === 'recipient' ? recipientNavItems : senderNavItems;
    });

    return {
      performLogout,
      displayedNavItems,
      toggleNavbar,
      navbar
    };
  },
  data() {
    return {
      logo: require('@/assets/images/logo.svg')
    };
  }
});
