
import { defineComponent, ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import { useRouter } from 'vue-router';
import validator from 'validator';

import { login } from "@/services/authService";

import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';
import LoaderAnimation from '@/components/LoaderAnimation.vue';
import PassphrasePrompt from '@/components/PassphrasePrompt.vue'; 

export default defineComponent({
  name: 'userLogin',
  components: {
    LoginSignupWrapper,
    LoaderAnimation,
		PassphrasePrompt
  },
  setup() {
    const router = useRouter(); 

    const email = ref('');
    const password = ref('');
    const emailValid = ref(true);
    const loginFail = ref(true);
    const loginErrorText = ref('Login Failed');
    const isLoading = ref(false);

		const showPassphrasePrompt = ref(false);
		const passphraseVerified = ref(false);
		const watchPassphrase = ref(false);

    const currentLangIndex = ref(0);    
    const welcomeText = ref([
      'Welcome back!', // English
      'ਵਾਪਸੀ ਤੇ ਸੁਆਗਤ ਹੈ!', // Punjabi
      'वापसी पर स्वागत है!' // Hindi
    ]);

    const cycleLanguage = () => {
      currentLangIndex.value = (currentLangIndex.value + 1) % welcomeText.value.length;
    };

    const currentLangIndexButton = ref(0);
    const buttonText = ref([
      'Login', // English
      'ਲਾਗਿਨ', // Punjabi
      'लॉग इन' // Hindi
    ]);

    const buttonTextCycle = computed(() => buttonText.value[currentLangIndexButton.value]);

    let languageInterval: number;

		

    onMounted(() => {
      languageInterval = window.setInterval(() => {
        currentLangIndexButton.value = (currentLangIndexButton.value + 1) % buttonText.value.length;
      }, 2000); // Change text every 3 seconds
    });

    onBeforeUnmount(() => {
      clearInterval(languageInterval);
    });

    const usrLogin = async () => {
      if (!validator.isEmail(email.value)) {
        emailValid.value = false;
        return;
      } else if (!password.value) {
        loginFail.value = false;
        loginErrorText.value = 'Please enter your password';
        return;
      } else {
				emailValid.value = true;
				showPassphrasePrompt.value = true;
				if (!watchPassphrase.value) {
					watchPassphrase.value = true;
					watch(passphraseVerified, async (newValue) => {
						if (newValue) {
							showPassphrasePrompt.value = false;
							try {
								isLoading.value = true;
								const result = await login(email.value, password.value);
								sessionStorage.setItem("user-auth", result.token);
								loginFail.value = true;
								// Navigate to senderHome route
								if (result.profile.country === 'Canada') {
									router.push({ name: 'senderHome' });  
								} else {
									router.push({ name: 'recipientHome' });  
								}
							} catch (error) {
								loginErrorText.value = error as string;
								loginFail.value = false;
								isLoading.value = false;
							}
						}
						passphraseVerified.value = false;
					});
				}
      }
    };

    return {
      email,
      password,
      emailValid,
      loginFail,
      loginErrorText,
      usrLogin,
      isLoading,
      currentLangIndex,
      welcomeText,
      cycleLanguage,
      buttonTextCycle,
      currentLangIndexButton,
			passphraseVerified,
			showPassphrasePrompt
    };
  },
})
