/* eslint-disable no-console */

import { register } from 'register-service-worker'

// Check if the device is an iOS device
function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}sw.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.');

      const message = isIOS()
        ? 'New content is available. Please close and reopen the app to update.'
        : 'New content is available. Do you want to reload the app?';

      const confirmed = window.confirm(message);

      if (!isIOS() && confirmed && registration && registration.waiting) {
        // Ask the new service worker to activate and take control
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });

        // Once the new service worker takes control, reload the page to fetch and display new content
        registration.waiting.addEventListener('statechange', function(event: Event) {
          const sw = event.target as ServiceWorker;
          if (sw && sw.state === 'activated') {
            window.location.reload();
          }
        });
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
