
import { defineComponent, ref } from 'vue';
import validator from 'validator';
import LoaderAnimation from '@/components/LoaderAnimation.vue';

export default defineComponent({
  name: 'signupEmail',
  components: {
    LoaderAnimation
  },
  setup(){
    const email = ref('');
    const emailValid = ref(true);
    const isLoading = ref(false);

    const submitEmail = () => {

      if (!validator.isEmail(email.value)) {
        emailValid.value = false;
      } else {

        emailValid.value = true;
        isLoading.value = true; 

      }
    }

    return {
      email,
      submitEmail,
      emailValid,
      isLoading
    }
  }
})
