
import { defineComponent, ref, computed } from 'vue';
import { store } from '@/store';
import { NewUser } from '@/types'; 
import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';
import { 
  capitalize, 
  validateMiddleInitial, 
  formatDOB, 
  isValidDOB,
  conformDOB,
  isValidPhone,
  formatPhoneNumber, 
  containsOnlyLetters 
} from '@/helpers/formatting';

export default defineComponent({
  name: 'createAccount',
  emits: ['next'],
  components: {
        LoginSignupWrapper,
    },
	props: {
		initialPhone: {
			type: String,
			default: ''
		}
	},
  setup(_, { emit }){

    // Getting country from the store
    const country = store.newUser?.country || 'Canada';

    const firstName = ref('');
    const lastName = ref('');
    const middleInitial = ref('');
    const dob = ref('');
    const phone = ref('');

    const firstNameValid = ref(true);
    const lastNameValid = ref(true);
    const dobValid = ref(true);
    const phoneValid = ref(true);

    const dobPlaceholder = computed(() => {
      switch (country) {
        case 'Canada':
          return 'Date of birth (YYYY-MM-DD) *';
        case 'India':
          return 'Date of birth (DD-MM-YYYY) *';
        case 'Philippines':
          return 'Date of birth (MM/DD/YYYY) *';
        default:
          return 'Date of birth *';
      }
    });

    const phonePlaceholder = computed(() => {
      switch (country) {
        case 'Canada':
          return 'e.g. (387) 345-6789 *';
        case 'India':
          return 'e.g. 12345 67890 *';
        case 'Philippines':
          return 'e.g. 1234 567 890 *';
        default:
          return 'Phone number *';
      }
    });

    const flag = computed(() => {
      switch(country) { 
        case 'Canada':
          return '🇨🇦';
        case 'India':
          return '🇮🇳';
        case 'Philippines':
          return '🇵🇭';
        default:
          return '🇺🇳';
      }
    });

    const areaCode = computed(() => {
      switch(country) { 
        case 'Canada':
          return '+1';
        case 'India':
          return '+91';
        case 'Philippines':
          return '+63';
        default:
          return '+0';
      }
    });

    const formCapitalize = () => {
      firstName.value = capitalize(firstName.value);
      lastName.value = capitalize(lastName.value);
    };

    const formValidateMiddleInitial = () => {
      middleInitial.value = validateMiddleInitial(middleInitial.value.charAt(0));
    };

    const formFormatDOB = (event: Event) => {
      const keyboardEvent = event as KeyboardEvent;
      // Check if the user is pressing the backspace key
      if (keyboardEvent.key === 'Backspace') {
        const input = event.target as HTMLInputElement;
        // Check if the cursor is positioned right after a dash and selectionStart and selectionEnd are not null
        if (input.selectionStart !== null && input.selectionEnd !== null && (input.selectionStart === 5 || input.selectionStart === 8)) {
          // If it is, move the cursor one position to the left
          input.selectionStart--;
          input.selectionEnd--;
        }
      }
      dob.value = formatDOB(dob.value, country);
    };

    const formFormatPhoneNumber = () => {
      phone.value = formatPhoneNumber(phone.value, country);
    };

    const submitAccountDetails = () => {

      firstNameValid.value = firstName.value.trim() !== '' && containsOnlyLetters(firstName.value);
      lastNameValid.value = lastName.value.trim() !== '' && containsOnlyLetters(lastName.value);

      dobValid.value = isValidDOB(dob.value, country);
      phoneValid.value = isValidPhone(phone.value, country);

      if (firstNameValid.value && lastNameValid.value && dobValid.value && phoneValid.value) {
        // Conform the date to YYYY-MM-DD
        const conformedDateOfBirth = conformDOB(dob.value, country);

        // Format the phone number to remove any special characters
        const formattedPhone = phone.value.replace(/\D/g, '');
        const internationalFormattedPhone = `${areaCode.value}${formattedPhone}`;

        // Merge existing newUser data from the store with the updated data from this step.

				if (!store.newUser) {
					// Handle the case where newUser is null, e.g., initialize it or throw an error
					console.error("New user data is not available.");
					return;
				}

				const updatedUser: NewUser = {
					email: store.newUser.email || '', // Default value if undefined
					sender: store.newUser.sender || true,
					firstName: firstName.value,
					lastName: lastName.value,
					middleInitial: middleInitial.value,
					dob: conformedDateOfBirth,
					phone: internationalFormattedPhone,
					country: store.newUser.country || 'Canada'
				};
				store.setNewUser(updatedUser);

        emit('next');
      }
    }

    return {
      firstName,
      lastName,
      middleInitial,
      dob,
      phone,
      firstNameValid,
      lastNameValid,
      dobValid,
      phoneValid,
      dobPlaceholder,
      formFormatDOB,
      phonePlaceholder,
      flag,
      areaCode,
      formFormatPhoneNumber,
      formCapitalize,
      formValidateMiddleInitial,
      submitAccountDetails
    }
  }
});
