
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';

import { FeeAndRateData, NewTransaction, BankInfo, CheckLimitsResponse } from '@/types';
import { getFeeAndRate } from '@/services/txService';
import { getBankInfo } from '@/services/senderService';
import { checkLimits } from '@/services/accountService'; 

import StepIndicator from '@/components/StepIndicator.vue';

export default defineComponent({
  name: 'SendDetails',
  components: {
    StepIndicator
  },
  setup() {
    const router = useRouter();

    const receiveAmount = ref(0);
    const formattedReceiveAmount = ref('');
    const selectedRecipient = computed(() => store.selectedRecipient);
    const selectedCountry = computed(() => selectedRecipient.value ? selectedRecipient.value.country : null);
    const currency = ref('' as 'INR' | 'PHP');
    if (selectedCountry.value === 'Philippines') {
      currency.value = 'PHP';
      receiveAmount.value = 10000;
      formattedReceiveAmount.value = '10,000.00';
    } else if (selectedCountry.value === 'India') {
      currency.value = 'INR';
      receiveAmount.value = 10000; 
      formattedReceiveAmount.value = '10,000.00';
    }

    const fee = ref(0);
    const rate = ref(0);
    const sendAmount = ref(0);
    const formattedSendAmount = ref('');
    const totalAmount = ref(0);

		const upperSendLimit = ref(0);
		const lowerSendLimit = ref(0);
		const sendAmountError = ref('');

		onMounted(async () => {
      try {
        const limitsResponse: CheckLimitsResponse = await checkLimits(); // Call checkLimits
        upperSendLimit.value = limitsResponse.upperLimit; 
				lowerSendLimit.value = limitsResponse.lowerLimit; 
      } catch (error) {
        console.error("Failed to fetch send limit:", error);
        // Handle error (e.g., show an error message to the user)
      }
    });

    const updateSendAmount = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        sendAmount.value = parseFloat((e.target as HTMLInputElement).value.replace(/[^0-9.-]+/g,"")) || 0;
      }
    };

    const updateReceiveAmount = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        receiveAmount.value = parseFloat((e.target as HTMLInputElement).value.replace(/[^0-9.-]+/g,"")) || 0;
      }
    };

    const handleSendFocus = () => {
      formattedSendAmount.value = "";
    };

    const handleReceiveFocus = () => {
      formattedReceiveAmount.value = "";
    };

    const handleSendBlur = (event: FocusEvent) => {
      sendAmount.value = parseFloat((event.target as HTMLInputElement).value.replace(/[^0-9.-]+/g,"")) || 0;
      formattedSendAmount.value = formatCurrency(sendAmount.value);
    };

    const handleReceiveBlur = (event: FocusEvent) => {
      receiveAmount.value = parseFloat((event.target as HTMLInputElement).value.replace(/[^0-9.-]+/g,"")) || 0;
      formattedReceiveAmount.value = formatCurrency(receiveAmount.value);
    };

    const formatCurrency = (amount: number) => {
      // Round up to two decimal places
      const roundedAmount = Math.round(amount * 100) / 100;
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(roundedAmount);
    };

    const calculateAmountsFromCAD = () => {
      totalAmount.value = Math.max(sendAmount.value - fee.value, 0);
      receiveAmount.value = Math.max(totalAmount.value * rate.value, 0);
      formattedReceiveAmount.value = formatCurrency(receiveAmount.value);
    };

    const calculateAmountsFromINRPHP = () => {
      sendAmount.value = Math.max(( receiveAmount.value / rate.value ) + fee.value, fee.value);
      totalAmount.value = Math.max(sendAmount.value - fee.value, 0);
      formattedSendAmount.value = formatCurrency(sendAmount.value);
    };

    watch(sendAmount, calculateAmountsFromCAD);
    watch(receiveAmount, calculateAmountsFromINRPHP);

    getFeeAndRate().then((data: FeeAndRateData) => {
      fee.value = Number(data.fee);

      rate.value = Number(data.rate);

      calculateAmountsFromINRPHP();
    });

    const goToReview = async () => {

			sendAmountError.value = '';

			if (upperSendLimit.value === 0) {
				sendAmountError.value = 'Pending EFT withdrawal, cannot send additional funds.';
				return; // Stop the method execution
			}

			// Check if the send amount exceeds the upper limit
			if (sendAmount.value > upperSendLimit.value) {
				sendAmountError.value = `You can only send up to $${upperSendLimit.value} for this transaction.`;
				return; // Stop the method execution
			}

			// Check if the send amount is below the lower limit
			if (sendAmount.value < lowerSendLimit.value) {
				sendAmountError.value = `The minimum for this transaction is $${lowerSendLimit.value}.`;
				return; // Stop the method execution
			}

      if(sendAmount.value && totalAmount.value && receiveAmount.value && fee.value) {
                
        const transactionDetails: NewTransaction = {
          sendAmount: sendAmount.value,
          totalFees: fee.value,
          totalAmount: totalAmount.value,
          recipientGets: receiveAmount.value,
          shouldArriveBy: '', // Add the actual value here
          destinationCurrency: currency.value
        };

        store.setTransactionDetails(transactionDetails);

        const bankInfoData: BankInfo = await getBankInfo();
        
        if (!bankInfoData.bankName) {
          router.push({ 
            name: 'connectBankAccount', 
            params: {source: 'sendMoney'}
          });
          return;
        } else {
          router.push({ name: 'reviewTransfer' });
        }        
      }
    }

    return {
      fee,
      rate,
      sendAmount,
      totalAmount,
      receiveAmount,
      formatCurrency,
      handleSendFocus,
      handleReceiveFocus,
      updateSendAmount,
      updateReceiveAmount,
      handleSendBlur,
      handleReceiveBlur,
      formattedSendAmount,
      formattedReceiveAmount,
      goToReview, 
      currency,
			sendAmountError
    };
  }
})
