// src/main.ts

import { createApp } from 'vue';
import App from './App.vue';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

// Custom styles
import './assets/styles/styles.css';

import './registerServiceWorker';
import router from './router';

const app = createApp(App);

app.use(router);


app.mount('#app');
