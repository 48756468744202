
import { defineComponent, ref } from 'vue';

import LoaderAnimation from '@/components/LoaderAnimation.vue';

// Import any necessary services for address change
// import { changeAddress } from '@/services/accountService';

export default defineComponent({
  name: 'ChangeAddress',
  components: {
    LoaderAnimation
  },
  setup() {

    const streetAddressOne = ref('');
    const streetAddressTwo = ref('');
    const city = ref('');
    const province = ref('');
    const postalCode = ref('');
    const country = ref('');

    const isLoading = ref(false);
    const updateError = ref(false);

    const updateAddress = async () => {
        isLoading.value = true; 

        /*
        try {
            // Call the API service to update the address
            await changeAddress(streetAddressOne.value, streetAddressTwo.value, city.value, province.value, postalCode.value, country.value);
            updateError.value = false;
            // Optionally redirect the user or display a success message
        } catch (error) {
            isLoading.value = false;
            updateError.value = true;
            console.error('An error occurred:', error);
        }
        */
    };

    return {
        streetAddressOne,
        streetAddressTwo,
        city,
        province,
        postalCode,
        country,
        updateError,
        updateAddress,
        isLoading
    };
  }
})
