
import { defineComponent, ref, Ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';
import SmartySDK from "smartystreets-javascript-sdk";
const SmartyCore = SmartySDK.core;
import { internationalAddressAutocomplete } from 'smartystreets-javascript-sdk';

import LoaderAnimation from '@/components/LoaderAnimation.vue';
import { updateProfile } from '@/services/accountService';

interface Suggestion {
  street: string;
  locality: string;
  administrativeArea: string;
  postalCode: string;
  addressId?: string;
  addressText?: string;
  entries?: number;
}

const credentials = new SmartyCore.SharedCredentials(process.env.VUE_APP_SMARTY_EMBEDDED_KEY ?? '');

export default defineComponent({
  name: 'CompleteProfile',
  components: {
    LoaderAnimation
  },
  setup() {

    const router = useRouter();
    
    const authenticatedUser = computed(() => store.authenticatedUser);
    const firstName = computed(() => authenticatedUser.value ? authenticatedUser.value.firstName : "");
    const lastName = computed(() => authenticatedUser.value ? authenticatedUser.value.lastName : "");

    const input = ref('');
    const suggestions: Ref<Suggestion[]> = ref([]);
    const selectedAddress: Ref<Suggestion | null> = ref(null);

    const selectedIdDocument = ref('');
    const isCertifyChecked = ref(false);
    const isDocIdEntered = ref('');

    const clientBuilder = new SmartyCore.ClientBuilder(credentials)
      .withLicenses(["international-autocomplete-v2-cloud"]);
    const client = clientBuilder.buildInternationalAddressAutocompleteClient();
    const country = "CAN";

    const isLoading = ref(false);

    const handleInputChange = async () => {
      if (input.value.length > 3) {    
        try {
          // eslint-disable-next-line
          const lookup = new internationalAddressAutocomplete.Lookup({ search: input.value, country: country } as any, country);
          const lookupResponse = await client.send(lookup);
          // eslint-disable-next-line
          suggestions.value = lookupResponse.result.map((item: any) => ({
            street: item.street || '',
            locality: item.locality || '',
            administrativeArea: item.administrativeArea || '',
            postalCode: item.postalCode || '',
            addressText: item.addressText || '',
            entries: item.entries ?? 0, // Default to 0 if entries is undefined
            addressId: item.addressId || ''
          }));
        } catch (error) {
          console.error(error);
          suggestions.value = [];
        }
      } else {
        suggestions.value = [];
      }
    };

    const selectAddress = async (suggestion: Suggestion) => {
      // Close the dropdown by clearing the suggestions
      suggestions.value = [];

      // Update the input value with the selected address text
      if (suggestion.addressText) {
        input.value = '';
      }

      if (suggestion.addressId) {
        // eslint-disable-next-line
        const lookup = new internationalAddressAutocomplete.Lookup({ addressId: suggestion.addressId, country: country } as any, country);
        const lookupResponse = await client.send(lookup);
        if (lookupResponse.result.length === 1) {
          const addressDetails = lookupResponse.result[0];
          selectedAddress.value = {
            street: addressDetails.street,
            locality: addressDetails.locality,
            administrativeArea: addressDetails.administrativeArea,
            postalCode: addressDetails.postalCode,
          };
        } else if (lookupResponse.result.length > 1) {
          // eslint-disable-next-line
          suggestions.value = lookupResponse.result.map((item: any) => ({
            street: item.street || '',
            locality: item.locality || '',
            administrativeArea: item.administrativeArea || '',
            postalCode: item.postalCode || '',
            addressText: item.addressText || '',
            entries: item.entries ?? 0, // Default to 0 if entries is undefined
            addressId: item.addressId || ''
          }));
        }
      }
    };

    const isContinueButtonDisabled = computed(() => {
      return !selectedAddress.value || !selectedIdDocument.value || !isCertifyChecked.value || !isDocIdEntered.value;
    });

    const handleContinueClick = async () => {
      if (!isContinueButtonDisabled.value) {
        isLoading.value = true;
        try {
          await updateProfile({

            streetAddress: selectedAddress.value?.street || '82 Germorda Dr',
            city: selectedAddress.value?.locality || 'Oakville',
            province: selectedAddress.value?.administrativeArea || 'ON',
            postalCode: selectedAddress.value?.postalCode || 'L6H 2R1',
            idType: selectedIdDocument.value,
            idNumber: isDocIdEntered.value
          });
          router.push({ name: 'profileCompletionConfirmation'});
        } catch (error) {
          router.push({ name: 'profileCompletionError'});
        }
        isLoading.value = false;
      }
    };

    return {
      input,
      suggestions,
      handleInputChange,
      selectAddress,
      firstName,
      lastName,
      selectedAddress,
      selectedIdDocument,
      isDocIdEntered,
      isCertifyChecked,
      isContinueButtonDisabled,
      handleContinueClick,
      isLoading
    };
  }
});
