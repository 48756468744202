
import { defineComponent, ref, onMounted } from 'vue';
import { getProfile } from '@/services/accountService'; 
import { getBankInfo } from '@/services/senderService'; 
import { UserProfile, BankInfo } from '@/types'; 

export default defineComponent({
  name: 'SenderAccount',
  setup() {
  const fullName = ref('');
  const email = ref('');
  const dob = ref('');
  const streetAddressOne = ref('');
  const streetAddressTwo = ref('');
  const city = ref('');
  const province = ref('');
  const postalCode = ref('');
  const country = ref('');
  const bankInfoDisplay = ref('');

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  onMounted(async () => {
    try {
    const profileData: UserProfile = await getProfile();

    fullName.value = `${profileData.firstName} ${profileData.lastName}`;
    email.value = profileData.email;
    dob.value = formatDate(profileData.dob);
    streetAddressOne.value = profileData.streetAddressOne;
    streetAddressTwo.value = profileData.streetAddressTwo;
    city.value = profileData.city;
    province.value = profileData.province;
    postalCode.value = profileData.postalCode;
    country.value = profileData.country;

    } catch (error) {
    console.error('Error fetching profile:', error);
    }

    try {
			const bankInfoData: BankInfo = await getBankInfo();
    
			// Check if bankName is null (or undefined)
			if (bankInfoData.bankName) {
				const firstTwoWords = bankInfoData.bankName.split(' ').slice(0, 2).join(' ');
				bankInfoDisplay.value = firstTwoWords + ' ' + bankInfoData.accountNumberHint;
			} else {
				bankInfoDisplay.value = ''; // Set it as empty string if no bank info
			}
    
    } catch (error) {
			console.error('Error fetching bank info:', error);
    }

  });

  return {
    fullName,
    email,
    formattedDob: dob,
    streetAddressOne,
    streetAddressTwo,
    city,
    province,
    postalCode,
    country,
    bankInfoDisplay
  };
  }
});
