
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store';

import LoginSignupWrapper from '@/components/LoginSignupWrapper.vue';
import LoaderAnimation from '@/components/LoaderAnimation.vue';

import { signupUser } from '@/services/usrService'; 
import { login } from "@/services/authService";

export default defineComponent({
  name: 'choosePassword',
  emits: ['next'],
  components: {
    LoginSignupWrapper,
    LoaderAnimation
  }, 
  setup(){

    const router = useRouter();
	
    const password = ref('');
    const validLength = ref(true);
    const containsLetter = ref(true);
    const containsNumber = ref(true);
    const containsUppercase = ref(true);
    const signupError = ref(false);
    const loginError = ref(false);
    const isLoading = ref(false); 

    const checkValidLength = (password: string) => password.length >= 9;
    const checkContainsLetter = (password: string) => /[a-zA-Z]/.test(password);
    const checkContainsNumber = (password: string) => /\d/.test(password);
    const checkContainsUppercase = (password: string) => /[A-Z]/.test(password);

    const setPassword = async () => {

      // Check if the password meets the requirements
      validLength.value = checkValidLength(password.value);
      containsLetter.value = checkContainsLetter(password.value);
      containsNumber.value = checkContainsNumber(password.value);
      containsUppercase.value = checkContainsUppercase(password.value);

      // If all requirements are met, you can continue or set any other logic
      if (validLength.value && containsLetter.value && containsNumber.value && containsUppercase.value) {
        isLoading.value = true; 

        const newUser = store.newUser;

        // Ensure that newUser is not null before proceeding
        if (newUser) {
          try {
            // Calling the API service to sign up the user
            await signupUser({ ...newUser, password: password.value });
            
            signupError.value = false;

            // Now that the signup was successful, let's log the user in.
            try {
              const loginResult = await login(newUser.email, password.value);
              sessionStorage.setItem("user-auth", loginResult.token);

              signupError.value = false;
              store.flushNewUser();
              router.push({ name: 'signupSuccess' });

            } catch (error) {
              loginError.value = true;
              console.error('Login failed after signup:', error);
            }
            
          } catch (error) {
            // Handle the error appropriately
            signupError.value = true;
            console.error('An error occurred:', error);
          } finally {
            isLoading.value = false; // End the loading animation, regardless of success or error
          }
        } else {
          signupError.value = true;
          console.error('NewUser object is null');
        }

      }
    };

    return {
      password,
      setPassword,
      validLength,
      containsLetter,
      containsNumber,
      containsUppercase,
      signupError,
      loginError,
      isLoading
    };
  }
})
