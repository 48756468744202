// @/services/accountService.ts

import { GenericAPIResponse, UserProfile, CheckAccountResult, CheckLimitsResponse, getPromoResponse } from '@/types';
import MarketingConstants from '@/MarketingConstants';

export const changePassword = async (oldPassword: string, newPassword: string): Promise<GenericAPIResponse> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/account/change-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({
      oldPassword: oldPassword,
      newPassword: newPassword
    })
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to change password');
  }

  return await response.json();
};

export const getProfile = async (): Promise<UserProfile> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  const response = await fetch(`${process.env.VUE_APP_API_URL}/account/get-profile`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });
  
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || "Failed to fetch profile");
  }

  const data = await response.json();

  return data;
};

type UpdateProfileRequest = {
  streetAddress?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  idType?: string;
  idNumber?: string;
};

export const updateProfile = async (requestData: UpdateProfileRequest): Promise<GenericAPIResponse> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  console.log(requestData)

  const response = await fetch(`${process.env.VUE_APP_API_URL}/account/update-profile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(requestData)
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to update profile');
  }

  return await response.json();
};

export const checkAccount = async (): Promise<CheckAccountResult> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  const response = await fetch(`${process.env.VUE_APP_API_URL}/account/check-account`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || "Failed to fetch profile");
  }

  const data = await response.json();

  return data;
};

export const checkLimits = async (): Promise<CheckLimitsResponse> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

  const response = await fetch(`${process.env.VUE_APP_API_URL}/account/check-limits`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || "Failed to check limits");
  }

  return await response.json();
};

export const getPromo = async (): Promise<getPromoResponse> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }

	const url = `${process.env.VUE_APP_API_URL}/account/get-promo?campaign=${encodeURIComponent(MarketingConstants.activeCampaign)}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || "Failed to check limits");
  }

  return await response.json();
};