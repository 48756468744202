// @/services/senderService.ts

import { Recipient, Transaction, BankInfo } from '@/types';

// Recipients

// 🚧 ERROR HANDLING HERE PLS

export const getRecipients = async (): Promise<Recipient[]> => {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/sender/get-recipients`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  const data = await response.json(); // Fetch the JSON data only once

  if (!response.ok) {
    throw new Error(data.message || 'Failed to fetch recipients');
  }

  return data.recipients;

};

export async function addRecipient(
  firstName: string,
  lastName: string,
  middleInitial: string,
  phoneNumber: string,
  bankAccount: string,
  ifsc: string,
  upi: string
)  {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }
  const recipientData = {
    firstName,
    middleInitial,
    lastName,
    phoneNumber,
    bankAccount,
    ifsc,
    upi
  };
  const response = await fetch(`${process.env.VUE_APP_API_URL}/sender/add-recipient`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(recipientData)
  });

  const data = await response.json(); 

  if (!response.ok) {
    throw new Error(data.message || 'Failed to add recipient');
  }

  return data.id;

}

export async function deleteRecipient(recipientId: string): Promise<void> {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/sender/delete-recipient`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({ recipientId })
  });

  if (!response.ok) {
    const data = await response.json(); // Fetch the JSON data to get the error message
    throw new Error(data.message || 'Failed to delete recipient');
  }
}

// Transactions

export async function getTransactions(): Promise<Transaction[]> {
  const token = sessionStorage.getItem('user-auth');
  if (!token) {
    throw new Error("No auth token found in sessionStorage");
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/sender/get-transactions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Failed to fetch transactions");
  }

  return data.transactions;
}

// Bank Info

export async function getBankInfo(): Promise<BankInfo> {
  const token = sessionStorage.getItem('user-auth')
  if (!token) {
    throw new Error("No auth token found in sessionStorage")
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/sender/get-bank-info`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.message || "Failed to fetch transactions")
  }

  return data
}