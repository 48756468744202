
import { defineComponent, ref, onMounted } from 'vue'
import { getTransactions} from "@/services/senderService";
import { Transaction } from "@/types";

export default defineComponent({
  name: 'SenderTransactions',

  setup () {
    const transactionList = ref<Transaction[]>([]); // Initialize as empty array
    const selectedTransaction = ref<Transaction | null>(null);

    onMounted(async () => {
      try {
          transactionList.value = await getTransactions(); // Fetch transactions          
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    });

    const getStatusDisplay = (transaction: Transaction) => {

      if (transaction.transferStatus !== 'Unknown') {
        return transaction.transferStatus;
      } else if (transaction.fundingStatus !== 'Unknown') {
        return transaction.fundingStatus;
      }
      return transaction.status;
    };

    const formatDate = (dateString: string) => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const date = new Date(dateString);
      const dayName = days[date.getDay()];
      const monthName = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      return `${dayName}, ${monthName} ${day} ${year}`;

      // Adding time: 
      
      // const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const ampm = hours >= 12 ? 'pm' : 'am';
      // return `${dayName}, ${monthName} ${day} ${year} ${hours % 12 || 12}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
      
    };

    const formatCurrency = (amount: number, currency: string) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code'
      }).format(amount);
      
      // Remove the currency code and any extra spaces
      const amountOnly = formatted.replace(`${currency}`, '').trim();

      // Append the currency code at the end
      return `${amountOnly} ${currency}`;
    };

    const showDetails = (transaction: Transaction) => {
      selectedTransaction.value = transaction;
    };

    const closeDetails = () => {
      selectedTransaction.value = null;
    };

    return {
      transactionList,
      selectedTransaction,
      getStatusDisplay,
      formatDate,
      formatCurrency,
      showDetails,
      closeDetails
    }
  },
})
