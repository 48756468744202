// @/helpers/formatting.ts

export const capitalize = (name: string): string => 
  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

export const validateMiddleInitial = (char: string): string =>
  char.match(/[a-zA-Z]/) ? char.toUpperCase() : '';

export const containsOnlyLetters = (str: string): boolean => 
  /^[a-zA-Z\s'’-]+$/g.test(str);

export const isValidDOB = (dateStr: string, country: string): boolean => {
  let regex;
  let year, month, day;

  const currentYear = new Date().getFullYear();

  switch (country) {
    case 'Canada':
      regex = /^(\d{4})-(\d{2})-(\d{2})$/;
      break;
    case 'India':
      regex = /^(\d{2})-(\d{2})-(\d{4})$/;
      break;
    case 'Philippines':
      regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      break;
    default:
      // A default regex, or throw an error if no matching country is found
      regex = /.*/; // match anything
      break; 
  }

  const match = dateStr.match(regex);
  
  if (!match) return false;

  // Extracting year, month and day based on country
  switch (country) {
    case 'Canada':
      year = parseInt(match[1]);
      month = parseInt(match[2]);
      day = parseInt(match[3]);
      break;
    case 'India':
    case 'Philippines':
      day = parseInt(match[1]);
      month = parseInt(match[2]);
      year = parseInt(match[3]);
      break;
    default:
      // For other formats, you can add more cases
      return false;
  }

  // Check for valid age range
  if (year >= currentYear || year <= currentYear - 100) return false;

  // Check for leap year
  const isLeap = (year: number) => (year % 4 === 0) && (year % 100 !== 0 || year % 400 === 0);

  // Check if month is valid
  if (month < 1 || month > 12) return false;

  // Check if day is valid for the month
  if (month === 2) {
    if (isLeap(year)) {
      if (day < 1 || day > 29) return false;
    } else {
      if (day < 1 || day > 28) return false;
    }
  } else if ([4, 6, 9, 11].includes(month)) {
    if (day < 1 || day > 30) return false;
  } else {
    if (day < 1 || day > 31) return false;
  }

  return true;
};

export const formatDOB = (value: string, country: string): string => {
  let digits = value.replace(/\D/g, ''); // strip all non-numeric characters

  switch (country) {
    case 'Canada':
      if (digits.length >= 6) {
        digits = digits.substring(0, 4) + '-' + digits.substring(4, 6) + '-' + digits.substring(6, 8);
      } else if (digits.length >= 4) {
        digits = digits.substring(0, 4) + '-' + digits.substring(4);
      } else if (digits.length === 4 || digits.length === 7) {
        digits += '-';
      }
      digits.substring(0, 10); // Maximum length is 10: YYYY-MM-DD
      break;

    case 'India':
    case 'Philippines': // India and Philippines have same format behavior, so we can group them
      if (digits.length >= 4) {
        digits = digits.substring(0, 2) + '-' + digits.substring(2, 4) + '-' + digits.substring(4, 8);
      } else if (digits.length >= 2) {
        digits = digits.substring(0, 2) + '-' + digits.substring(2);
      }
      digits.substring(0, 10); // Maximum length is 10: DD-MM-YYYY or MM/DD/YYYY
      break;

    default:
      break;
  }
  
  return digits;
};

export const conformDOB = (dob: string, country: string): string => {
  let conformedDOB: string;
  
  switch (country) {
    case 'Canada': {
      conformedDOB = dob; // already in YYYY-MM-DD format
      break;
    }
    case 'India': {
      const [day, month, year] = dob.split('-');
      conformedDOB = `${year}-${month}-${day}`;
      break;
    }
    case 'Philippines': {
      const [m, d, y] = dob.split('/');
      conformedDOB = `${y}-${m}-${d}`;
      break;
    }
    default: {
      conformedDOB = dob; // you might want to handle the default case as well.
      break;
    }
  }
  
  return conformedDOB;
};

export const isValidPhone = (phoneNumber: string, country: string): boolean => {
  let regex;

  switch (country) {
    case 'Canada':
      regex = /^\(\d{3}\) \d{3}-\d{4}$/; 
      break;

    case 'India':
      regex = /^\d{5} \d{5}$/;
      break;

    case 'Philippines':
      regex = /^\d{4} \d{3} \d{4}$/;
      break;

    default:
      // A default regex, or throw an error if no matching country is found
      regex = /.*/; // match anything
      break; 
  }

  return !!phoneNumber.match(regex);
};

export const formatPhoneNumber = (value: string, country: string): string => {
  let digits = value.replace(/\D/g, ''); // strip all non-numeric characters

  switch (country) {
    case 'Canada':
      if (digits.length > 3) {
        digits = '(' + digits.substring(0, 3) + ') ' + digits.substring(3);
      }
      if (digits.length > 6) {
        digits = digits.substring(0, 9) + '-' + digits.substring(9, 13);
      }
      return digits.substring(0, 14); // Maximum length is 14: (XXX) XXX-XXXX

    case 'India':
      if (digits.length > 5) {
        digits = digits.substring(0, 5) + ' ' + digits.substring(5, 10);
      }
      return digits.substring(0, 11); // Maximum length is 11: XXXXX XXXXX

    case 'Philippines':
      if (digits.length > 4) {
        digits = digits.substring(0, 4) + ' ' + digits.substring(4);
      }
      if (digits.length > 8) {
        digits = digits.substring(0, 8) + ' ' + digits.substring(8, 12);
      }
      return digits.substring(0, 13); // Maximum length is 13: XXXX XXX XXXX

    default:
      return digits;
  }
};

export const toE164Format = (formattedPhoneNumber: string, country: string): string => {
  // Strip all non-numeric characters
  const digits = formattedPhoneNumber.replace(/\D/g, '');

  // Prepend country code based on the country
  let countryCode = '';
  switch (country) {
    case 'Canada':
      countryCode = '+1';
      break;
    case 'India':
      countryCode = '+91';
      break;
    case 'Philippines':
      countryCode = '+63';
      break;
    // Add more countries and their codes as needed
    default:
      throw new Error('Unsupported country for E.164 conversion');
  }

  return countryCode + digits;
};
