
import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue';
import moment from 'moment';

interface BeforeInstallPromptEvent extends Event {
    prompt: () => void;
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed', platform: string }>;
}

// Declare a specific Navigator type for standalone
interface StandaloneNavigator extends Navigator {
    standalone?: boolean;
}

export default defineComponent({
  name: 'InstallPrompt',
  setup() {

    const buildDate = process.env.NODE_ENV === 'production'
      ? process.env.BUILD_DATE
      : moment().format('MM/DD/YY h:mm');

    const showInstallPrompt = ref(false);
    const installPromptEvent = ref<BeforeInstallPromptEvent | null>(null);

    const beforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      installPromptEvent.value = e;
      showInstallPrompt.value = true;
    }

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', beforeInstallPrompt as (ev: Event) => void);
    })

    onUnmounted(() => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt as (ev: Event) => void);
    })

    const installApp = async () => {
      console.log('Install clicked...');

      showInstallPrompt.value = false;
      
      if (installPromptEvent.value) {
        installPromptEvent.value.prompt();
        const choiceResult = await installPromptEvent.value.userChoice;
        installPromptEvent.value = null;

        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
      }
    }

    const deviceType = computed(() => {
       // Check user agent to determine the device type
       if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
         return "iOS Device";
       } else if (/Android/i.test(navigator.userAgent)) {
         return "Android Device";
       } else if (/Chrome/i.test(navigator.userAgent) && !/Edg/i.test(navigator.userAgent)) {
         return "Chrome Desktop";
       } else if (/Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent) && !/Android/i.test(navigator.userAgent)) {
         return "Safari Desktop";
       } else {
         return "Other Device";
       }
     });

    const isPWAInstalled = computed(() => {
      if (deviceType.value === "iOS Device" && 'standalone' in window.navigator) {
        return ((window.navigator as unknown) as StandaloneNavigator).standalone;
      } else if ((deviceType.value === "Android Device" || deviceType.value === "Chrome Desktop") && window.matchMedia('(display-mode: standalone)').matches) {
        return true;
      } else {
        return false;
      }
    });

    const showPrompt = computed(() => {
      if (deviceType.value === "Safari Desktop") return false;
      return !isPWAInstalled.value;
    });

    return {
      buildDate,
      showInstallPrompt,
      installApp,
      deviceType,
      isPWAInstalled,
      showPrompt
    };

  }
});
