

import { defineComponent, onMounted, computed } from 'vue';
import { store } from '@/store';
import { AuthenticatedUser, CheckAccountResult } from '@/types';
import { checkAccount } from '@/services/accountService';

import AfterSignupCard from '@/components/home-cards/AfterSingupCard.vue';
import AfterProfileCompleteCard from '@/components/home-cards/AfterProfileCompleteCard.vue';
import AfterFirstTxCard from '@/components/home-cards/AfterFirstTxCard.vue';


export default defineComponent({
  name: 'SenderHome',
  components: {
		AfterSignupCard,
		AfterProfileCompleteCard,
		AfterFirstTxCard

  },
  setup() {

		const fetchAccountData = async () => {
			try {
				const data = await checkAccount(); // Directly call the checkAccount service
				store.setAccountCheckData(data); 
			} catch (error) {
				console.error("Failed to fetch account check data:", error);
				// Handle the error as needed
			}
    };

		onMounted(() => {
			fetchAccountData();
		});

		const formatCurrency = (value: number, currency: string, locale = 'en-CA') => {
			return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
		};

		const accountCheckData = computed(() => store.accountCheckData);

		const cardInfo = computed(() => {
			const data: CheckAccountResult | null = store.accountCheckData;
			const user: AuthenticatedUser | null = store.authenticatedUser;
			return {
				firstName: user?.firstName ?? '',
				lastName: user?.lastName ?? '',
				rateOfficial: formatCurrency(data?.rateOfficial ?? 0, 'INR'),
				rateRoundUp: formatCurrency(data?.rateRoundUp ?? 0, 'INR'),
				last30dayVolume: formatCurrency(data?.last30dayVolume ?? 0, 'CAD')
			};
		});
    
	return {
		cardInfo,
		accountCheckData
	};
  }
})
