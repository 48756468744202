
import { defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'ConnectConfirmation',
  setup() {
    const router = useRouter();
    const route = useRoute();

    const sourceParam = route.params.source;

    const buttonText = () => {
      if (sourceParam === 'account') {
        return 'Back to account';
      } else if (sourceParam === 'sendMoney') {
        return 'Finish transfer';
      }
      return null;
    }

    const handleButtonClick = () => {
      if (sourceParam === 'account') {
        router.push({ name: 'senderAccount' });
      } else if (sourceParam === 'sendMoney') {
        router.push({ name: 'reviewTransfer' });
      }
    };

    return {
      handleButtonClick,
      buttonText: buttonText()
    };
  }
});
